import React, { useState } from 'react';
import StockCell from "../StockCell";
import { isEmptyField } from '../../utils/helpers';

function MutualFundsList({ data }) {
  // Check if data is valid and has elements
  const isValidData = !isEmptyField(data);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [activeSortKey, setActiveSortKey] = useState(null);
  // Show message if data is not valid
  if (!isValidData) {
    return (
      <div className="broker-main-container mutual-fund">
        <p>No mutual fund data available.</p>
      </div>
    );
  }

  // Function to handle sorting logic
  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setActiveSortKey(key); // Set the active sort key
  };

  return (
    <div className="broker-main-container mutual-fund">
      <div className='filter-container'>
        <div className={`filter ${activeSortKey === 'name' ? 'active' : ''}`} onClick={() => handleSort('name')}>Name <i className="fas fa-caret-down" aria-hidden="true"></i></div>
        <div className={`filter ${activeSortKey === 'investment' ? 'active' : ''}`} onClick={() => handleSort('investment')}>Investment <i className="fas fa-caret-down" aria-hidden="true"></i></div>
        <div className={`filter ${activeSortKey === 'curr_val' ? 'active' : ''}`} onClick={() => handleSort('curr_val')}>Current Value <i className="fas fa-caret-down" aria-hidden="true"></i></div>
        <div className={`filter ${activeSortKey === 'returns' ? 'active' : ''}`} onClick={() => handleSort('returns')}>Return <i className="fas fa-caret-down" aria-hidden="true"></i></div>
      </div>
      {sortedData.map((fundDetails, index) => (
        <StockCell key={index} data={fundDetails} module={'mutualfund'} />
      ))}
    </div>
  );
}

export default MutualFundsList;
