import React, { useState, useRef, useEffect } from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar } from "cdbreact";
import { NavLink } from 'react-router-dom';

const Navbar = ({toggleSidebar}) => {
    // State to track if the menu is active or not
    const [isActive, setIsActive] = useState(false);
    // State to track if the sub-menu is active or not
    const [isSubActive, setIsSubActive] = useState(false);
    // State to track if the profile is active or not
    const [isProfileActive, setIsProfileActive] = useState(false);

    // Refs for the menu and profile components
    const menuRef = useRef(null);
    const profileRef = useRef(null);

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsActive(prev => !prev);
        setIsProfileActive(false); // Close profile if menu is toggled
    };

    // Function to toggle the sub-menu
    const toggleSubMenu = () => {
        setIsSubActive(prev => !prev);
    };

    // Function to toggle the profile
    const toggleProfile = () => {
        setIsProfileActive(prev => !prev);
        setIsActive(false); // Close menu if profile is toggled
    };

    // Function to handle clicks outside of the menu and profile
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && 
            profileRef.current && !profileRef.current.contains(event.target)) {
            setIsActive(false);
            setIsProfileActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Header style={{ background: "#ffffff", color: "#000000" }}>
            <div className="form-inline ml-auto">
                <span onClick={toggleSidebar} className='toggle-bar'>
                    <i className="fa fa-bars fa-large"></i>
                    <span className="navbar-brand text-primary mr-0">MoneyLens</span>
                </span>
            </div>
            <CDBNavbar dark expand="md" scrolling className="justify-content-end">
                <div className="ml-auto">
                <div className="menu-container">
                    <NavLink exact='true' to="/networth" activeclassname="activeClicked">NetWorth</NavLink>
                    <NavLink exact='true' to="/assets" activeclassname="activeClicked">Assets</NavLink>
                    <NavLink exact='true' to="/debts" activeclassname="activeClicked">Debts</NavLink>
                </div>
                <div>
                    <span style={{ marginLeft: '15px', cursor: 'pointer' }} onClick={toggleMenu}>
                    UserName
                        <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i>
                    </span>
                    <img alt="profileImage" src="images/pane4.png" style={{ width: "3rem", height: "3rem" }} onClick={toggleProfile} />
                </div>
                </div>
                <div ref={menuRef} className={`menu ${isActive ? 'active' : ''}`}>
                    <ul>
                        <li>
                            <a href="#" className="active">UserName</a>
                            <button className="options" onClick={toggleSubMenu}></button>
                            <div className={`submenu ${isSubActive ? 'active' : ''}`}>
                                <ul>
                                    <li><a href="#" className="">Rename Portfolio</a></li>
                                    <li className="newPSection"><a href="#">Delete</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="newPSection"><a href="#">+ New Portfolio</a></li>
                    </ul>
                </div>
                <div ref={profileRef} className={`profile ${isProfileActive ? 'active' : ''}`}>
                    <div className="profile-section">
                        <img alt="profileImage" src="images/pane4.png" style={{ width: "3rem", height: "3rem" }} />
                        <div className="profile-name">Ahmar</div>
                        <div className="profile-info">ahmar.khan@moneylens.in</div>
                    </div>
                    <div className="newPSection">
                        <ul>
                            <li><a href="#" className="active">Settings</a></li>
                            <li><a href="#">Sign Out</a></li>
                        </ul>
                    </div>
                </div>
            </CDBNavbar>
        </Header>
    );
};

export default Navbar;
