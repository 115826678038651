import React, { useState, useEffect } from "react";
import { isNegative } from '../utils/helpers';
import classNames from 'classnames';
import "../css/TrendingStocks.css"; // Import the CSS file for styling


const TrendingStocks = ({stocksData}) => {
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const [progress, setProgress] = useState(0); // Progress state for the circular progress bar

  useEffect(() => {
    // Update the current logo index and progress based on the length of stocksData
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % stocksData.length); // Move to next logo
          return 0; // Reset progress
        }
        return prevProgress + 1; // Increment progress
      });
    }, 100); // Adjust speed of progress (100ms for smooth progress)

    return () => clearInterval(progressInterval); // Cleanup interval on component unmount
  }, [stocksData.length]); // Depend on stocksData length to reset interval if data changes

  const activeLogo = stocksData[currentLogoIndex];
  const otherLogos = stocksData.filter((_, index) => index !== currentLogoIndex); // Get all logos except the active one

  // Determine the rotation class based on whether the change value is negative
  const returnsPercentage = isNegative(parseFloat(activeLogo.change));
  const rotateClass = returnsPercentage ? 'rotate-text' : '';
  const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';

  return (
    <div className="slider-container">
      <div className="slider-content">
        <div className="logos">
          <div className="logo-item active">
            <div className="progress-circle">
              <svg className="progress-svg" width="60" height="60">
                <circle
                  className="progress-circle-background"
                  cx="30"
                  cy="30"
                  r="28"
                />
                <circle
                  className={classNames('progress-circle-bar', profitLossClass)}
                  cx="30"
                  cy="30"
                  r="28"
                  style={{ strokeDashoffset: 176 - (176 * progress) / 100 }}
                />
              </svg>
              <img src={activeLogo.logo} alt={activeLogo.name} className="logo-image" />
            </div>
            <svg id="triangle" viewBox="0 0 40 40" className={classNames('down-arrow fill-brand-blue-azureRadiance', profitLossClass)}><polygon points="20 15, 40 40, 0 40"></polygon></svg>
          </div>
          {otherLogos.map((logo) => (
            <div key={logo.id} className="logo-item">
              <img src={logo.logo} alt={logo.name} className="logo-image" />
            </div>
          ))}
        </div>
        <div className="logo-details">
          <p className="fund-name">{activeLogo.name}</p>
          <div className="fund-price-container">
            <p className="fund-price">{activeLogo.price}</p>
            <p className={classNames('', profitLossClass)}>
                <span className={classNames('', rotateClass)}>▲</span>{activeLogo.change}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingStocks;
