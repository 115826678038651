import React from 'react';
import '../css/TransactionList.css';
import { formatRupees, formatDate } from '../utils/helpers';

const TransactionList = ({data}) => {
  console.log(data)
  return (
    <div className="transaction-card-list">
      {data.map((group, index) => (
        <div key={index}>
          <div className="date">{formatDate(group.transaction_date)}</div>
          {group.transactions.map((transaction, i) => (
            <div className="broker-cell-container" data-key={transaction.id}>
              <div className="broker-cell-row">
                <div className="broker-cell-icon">
                  <img loading="lazy" width="32" height="32" decoding="async" data-nimg="1" src={transaction.icon} alt={transaction.name} style={{color: 'transparent'}} />
                </div>
                <div className="broker-cell-name-container transaction-info">
                  <p className="broker-cell-name" style={{textAlign: 'unset'}}>{transaction.name}</p>
                  <span className={`status ${transaction.status.toLowerCase()}`}>{transaction.status}</span>
                </div>
              </div>
              <div className="broker-cell-row padding-unset">
                <div className="broker-cell-name-container broker-cell-details">
                  <p className="broker-cell-name broker-name-color">Quantity: <span className="broker-cell-account">{transaction.quantity}</span></p>
                  {/* <p className="broker-cell-account">{transaction.quantity}</p> */}
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                  <div className="broker-cell-name broker-name-color">Total amount: <span className="broker-cell-account">₹{formatRupees(transaction.amount)}</span></div>
                  {/* <div className="broker-cell-account">₹{formatRupees(transaction.amount)}</div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TransactionList;
