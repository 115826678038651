import React, { useState, useRef, useEffect } from "react";
import '../../css/PreciousMetalsDetails.css';
import { message } from "antd";
import { formatDate } from "../../utils/helpers";

function PreciousMetalsDetails() {
    const [editingRowId, setEditingRowId] = useState(null); // Track the row being edited
    const [metalsDetails, setMetalsDetails] = useState([
        {
            id: 1,
            metalsName: 'Gold',
            investedAmount: '10,029,00', // Field for the invested amount
            currentValue: '10,029,00',
            weight: '100', // Field for the weight in grams
            returns: '10',
            investedDate: '1724371200000', // Field for the date of investment
        },
        {
            id: 2,
            metalsName: 'Gold',
            investedAmount: '5,000,00', // Field for the invested amount
            currentValue: '5,000,00',
            weight: '50', // Field for the weight in grams
            returns: '5',
            investedDate: '1724371200000', // Field for the date of investment
        }
        // Add more details as needed
    ]);

    const [originalDetails, setOriginalDetails] = useState([]); // Backup of original details
    const [error, setError] = useState(''); // State for error message

    const inputRefs = useRef({}); // Create a ref object to store input references

    useEffect(() => {
        // Focus the first editable cell when editing starts
        if (editingRowId !== null && inputRefs.current[editingRowId]) {
            inputRefs.current[editingRowId].focus();
        }
    }, [editingRowId]);

    const handleEdit = (id) => {
        // Backup the original data before editing
        const backup = metalsDetails.find(details => details.id === id);
        setOriginalDetails({ ...backup });
        setEditingRowId(id); // Set the row ID being edited
    };

    const handleSave = (id) => {
        const updatedDetails = metalsDetails.find(details => details.id === id);
        
        // Example API call
        fetch('/api/update-metals', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedDetails),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update data');
                }
                return response.json();
            })
            .then(data => {
                // Handle success (if needed)
                message.success('Update successful');
                setEditingRowId(null); // Reset editing row ID
                setError(''); // Clear any existing error message
            })
            .catch(error => {
                // Revert to original data if API call fails
                const revertedDetails = metalsDetails.map(details =>
                    details.id === id ? originalDetails : details
                );
                setMetalsDetails(revertedDetails);
                setEditingRowId(null); // Reset editing row ID
                message.error({
                    content: `Failed to save changes. Please try again.`,
                    duration: 3, // Duration in seconds
                });
            });
    };

    const handleInputChange = (e, id, field) => {
        const newDetails = metalsDetails.map(details =>
            details.id === id ? { ...details, [field]: e.target.textContent } : details
        );
        setMetalsDetails(newDetails);
    };

    return (
        <div className="precious-metals-details">
            {error && <div className="error-message">{error}</div>}
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Metals</th>
                            <th scope="col">Investment</th>
                            <th scope="col">Current Value</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Returns</th>
                            <th scope="col">Investment Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {metalsDetails.map(details => (
                            <tr key={details.id}>
                                <th scope="row">
                                    <img loading="lazy" width="18" height="18" decoding="async" data-nimg="1" src="../../../images/gold.png" alt='icon'/>
                                </th>
                                <td>
                                    {details.metalsName}
                                </td>
                                <td
                                    contentEditable={editingRowId === details.id}
                                    onBlur={(e) => handleInputChange(e, details.id, 'investedAmount')}
                                    suppressContentEditableWarning
                                >
                                    ₹{details.investedAmount}
                                </td>
                                <td
                                    contentEditable={editingRowId === details.id}
                                    onBlur={(e) => handleInputChange(e, details.id, 'currentValue')}
                                    suppressContentEditableWarning
                                >
                                    ₹{details.currentValue}
                                </td>
                                <td
                                    contentEditable={editingRowId === details.id}
                                    onBlur={(e) => handleInputChange(e, details.id, 'weight')}
                                    suppressContentEditableWarning
                                >
                                    {details.weight} gm
                                </td>
                                <td
                                    contentEditable={editingRowId === details.id}
                                    onBlur={(e) => handleInputChange(e, details.id, 'returns')}
                                    suppressContentEditableWarning
                                >
                                    {details.returns}%
                                </td>
                                <td
                                    contentEditable={editingRowId === details.id}
                                    onBlur={(e) => handleInputChange(e, details.id, 'investedDate')}
                                    suppressContentEditableWarning
                                >
                                    {formatDate(parseInt(details.investedDate))}
                                </td>
                                <td>
                                    {editingRowId === details.id ? (
                                        <i className='fas fa-save' onClick={() => handleSave(details.id)}></i>
                                    ) : (
                                        <i className='fas fa-edit' onClick={() => handleEdit(details.id)}></i>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PreciousMetalsDetails;
