// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap'; // Import Modal, Button, Form, Tabs, and Tab components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees } from '../../utils/helpers';

// Initial form data state
const initialFormData = {
    name: '', // Crypto name
    quantity: '', // Quantity
    price: '', // Purchase price
    date: '', // Purchase date
    type: 'buy', // Transaction type: buy or sell
};

// Define the CryptoCurrencyForm functional component
const CryptoCurrencyForm = ({ isOpen, closeModal, onFormSubmit }) => {
    // State for form validation and form data
    const [validated, setValidated] = useState(false); // Validation state
    const [formData, setFormData] = useState(initialFormData); // Form data state
    const [isFormDirty, setIsFormDirty] = useState(false); // Flag to check if form has been modified
    const [transactionType, setTransactionType] = useState('buy'); // State to keep track of the transaction type
    const [currency, setCurrency] = useState('INR'); // Added state for currency toggle

    // Effect to reset form data when modal opens
    useEffect(() => {
        if (isOpen) {
            setFormData(initialFormData); // Reset form data if modal is open
            setValidated(false); // Clear validation state
            setIsFormDirty(false); // Clear dirty flag
        }
    }, [isOpen]);

    // Handle tab change for transaction type
    const handleTabSelect = (type) => {
        setTransactionType(type); // Update the transaction type
        setFormData((prevData) => ({ ...prevData, type })); // Update form data with the selected type
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target; // Get input field ID and value
        let newValue = value;

        // If input is for a number field, remove commas for processing
        if (id === 'price') {
            newValue = value.replace(/,/g, ''); // Remove commas
            newValue = formatRupees(newValue); // Add commas
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true); // Mark form as dirty
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const form = event.currentTarget; // Get the form element

        // Check form validity
        if (form.checkValidity() === false) {
            event.stopPropagation(); // Stop submission if form is invalid
            setValidated(true); // Show validation feedback
        } else {
            try {
                // Replace this URL with your actual API endpoint
                const response = await fetch('https://your-api-endpoint.com/submit', {
                    method: 'POST', // Use POST method for form submission
                    headers: {
                        'Content-Type': 'application/json', // Set content type to JSON
                    },
                    body: JSON.stringify(formData), // Convert formData to JSON
                });

                if (response.ok) {
                    // Handle successful response
                    closeModal(); // Close the modal on success
                } else {
                    // Handle API errors
                    console.error('API Error:', response.statusText);
                }
            } catch (error) {
                // Handle network or other errors
                console.error('API Error:', error);
                console.log(formData)
                // Call the parent's callback function with form data
                onFormSubmit({ ...formData, id: Date.now() }); // Pass the updated object to the callback
                closeModal(); // Close the modal
            }
        }
    };

    // Check if all fields are empty
    const areFieldsEmpty = Object.values(formData).every(value => value === '');

    // Handle modal close
    const handleClose = () => {
        // Only ask for confirmation if the form is dirty and not all fields are empty
        if (isFormDirty && !areFieldsEmpty) {
            const confirmClose = window.confirm('Are you sure you want to exit? Unsaved changes will be lost.');
            if (!confirmClose) {
                return; // Prevent closing if user cancels
            }
        }
        closeModal(); // Close the modal
    };

    const handleCurrencyToggle = () => {
        setCurrency(currency === 'INR' ? 'USD' : 'INR');
    };
    // Render the form modal
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>
                {/* Tabs for switching between Buy and Sell */}
                <div className="toggle-button-group">
                    {/* Button for Buy */}
                    <button
                        className={`toggle-button ${transactionType === 'buy' ? 'active' : ''}`}
                        onClick={() => handleTabSelect('buy')}
                    >
                        Buy
                    </button>
                    {/* Button for Sell */}
                    <button
                        className={`toggle-button ${transactionType === 'sell' ? 'active' : ''}`}
                        onClick={() => handleTabSelect('sell')}
                    >
                        Sell
                    </button>
                </div>

                {/* Form with validation */}
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                        {/* <Form.Label>Crypto Currency</Form.Label> */}
                        <Form.Select
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a crypto currency</option>
                            <option value="Bitcoin">Bitcoin</option>
                            <option value="Ethereum">Ethereum</option>
                            <option value="Ripple">Ripple</option>
                            {/* Add more options as needed */}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please select the name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="quantity">
                        <Form.Control
                            type="number"
                            placeholder="Quantity"
                            value={formData.quantity}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="price">
                        <div className="input-container">
                            <span className="input-label">{currency === 'USD' ? '$' : '₹'}</span>
                            <Form.Control
                                type="text"
                                placeholder="Price *"
                                value={formData.price}
                                autoComplete="off"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Please enter the price.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="currencySwitch">Add price in USD</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="currencySwitch" onChange={handleCurrencyToggle}/>
                    </div>
                    <Form.Group controlId="date">
                        <Form.Control
                            type="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            placeholder="Date"
                            style={{
                                width: '100%',
                                cursor: 'pointer',
                            }}
                            onFocus={(e) => e.target.showPicker()} // This makes the input field open the date picker when clicked
                        />
                    </Form.Group>
                    <Form.Group controlId="propertySubmit" className="btn-container">
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

// Export the RealEstateForm component as the default export
export default CryptoCurrencyForm;
