// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form, Nav, Tab } from 'react-bootstrap'; // Import Modal, Button, and Form components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees } from '../../utils/helpers';

// Define the PreciousMetalsForm functional component
const PreciousMetalsForm = ({ isOpen, closeModal, onFormSubmit }) => {
    // Initial form data template for each metal
    const initialFormData = {
        investedAmount: '', // Field for the invested amount
        weight: '', // Field for the weight in grams
        investedDate: '', // Field for the date of investment
    };
  
    // State to manage the form data for each metal
    const [goldFormData, setGoldFormData] = useState({ metalsName: 'gold', ...initialFormData }); // State for Gold form data
    const [silverFormData, setSilverFormData] = useState({ metalsName: 'silver', ...initialFormData }); // State for Silver form data
    const [palladiumFormData, setPalladiumFormData] = useState({ metalsName: 'palladium', ...initialFormData }); // State for Palladium form data
    const [platinumFormData, setPlatinumFormData] = useState({ metalsName: 'platinum', ...initialFormData }); // State for Platinum form data

    // State to manage form validation for each metal
    const [validated, setValidated] = useState({ gold: false, silver: false, palladium: false, platinum: false }); // Validation state
    // State to manage dirty form status for each metal
    const [isFormDirty, setIsFormDirty] = useState({ gold: false, silver: false, palladium: false, platinum: false }); // Dirty flag state

    // State to manage the active tab
    const [activeKey, setActiveKey] = useState('gold'); // Default to 'gold' tab
  
    // Effect to reset the form data and active tab when the modal opens or filledData changes
    useEffect(() => {
        if (isOpen) { // If the modal is open
           // Reset form data for each metal to initial values
           setGoldFormData({ metalsName: 'gold', ...initialFormData });
           setSilverFormData({ metalsName: 'silver', ...initialFormData });
           setPalladiumFormData({ metalsName: 'palladium', ...initialFormData });
           setPlatinumFormData({ metalsName: 'platinum', ...initialFormData });

           // Reset validation and dirty states for each metal
           setValidated({ gold: false, silver: false, palladium: false, platinum: false });
           setIsFormDirty({ gold: false, silver: false, palladium: false, platinum: false });

           // Set the active tab to 'gold'
           setActiveKey('gold');
        }
    }, [isOpen]); // Dependency array to trigger effect
  
    // Function to handle input changes for any metal's form
    const handleInputChange = (e, metal) => {
        const { id, value } = e.target; // Destructure input ID and value
        let newValue = value;
        // If input is for a number field, remove commas for processing
        if (id === 'investedAmount') {
            let rawValue = value.replace(/,/g, ''); // Remove commas from the input value
            newValue = formatRupees(rawValue); // Format the number with commas
        }
        // Determine which form's state to update based on the metal type
        const updatedFormData = (metal === 'gold' ? goldFormData :
                                metal === 'silver' ? silverFormData :
                                metal === 'palladium' ? palladiumFormData : platinumFormData);
        // Determine which setter function to use for the specific metal
        const setFormData = (metal === 'gold' ? setGoldFormData :
                             metal === 'silver' ? setSilverFormData :
                             metal === 'palladium' ? setPalladiumFormData : setPlatinumFormData);

        // Update the form state for the selected metal
        setFormData({ ...updatedFormData, [id]: newValue });
        // Mark the form as dirty (modified)
        setIsFormDirty({ ...isFormDirty, [metal]: true });
    };
  
    // Handle form submission
    const handleSubmit = async (event, metal) => {
        event.preventDefault(); // Prevent default form submission behavior
        const form = event.currentTarget; // Get the form element
    
        // Check form validity
        if (form.checkValidity() === false) {
            event.stopPropagation(); // Stop submission if form is invalid
            setValidated({ ...validated, [metal]: true }); // Update validation state for the metal
        } else {
            // Select the form data for the specific metal
            const formData = (metal === 'gold' ? goldFormData :
                metal === 'silver' ? silverFormData :
                metal === 'palladium' ? palladiumFormData : platinumFormData);
            try {
                // Replace this URL with your actual API endpoint
                const response = await fetch('https://your-api-endpoint.com/submit', {
                    method: 'POST', // Use POST method for form submission
                    headers: {
                        'Content-Type': 'application/json', // Set content type to JSON
                    },
                    body: JSON.stringify(formData), // Convert formData to JSON
                });
        
                if (response.ok) {
                    // Handle successful response
                    closeModal(); // Close the modal on success
                } else {
                    // Handle API errors
                    console.error('API Error:', response.statusText);
                }
            } catch (error) {
                // Handle network or other errors
                console.error('API Error:', error);
                // Call the parent's callback function with form data
                onFormSubmit({ ...formData, id : metal }); // Pass the updated object to the callback
                closeModal(); // Close the modal
            }
        }
    };

    // Handle submission for all forms
    const handleSubmitAll = (e) => {
        e.preventDefault();

        // Iterate through each form
        if (isFormDirty.gold) handleSubmit(e, 'gold');
        if (isFormDirty.silver) handleSubmit(e, 'silver');
        if (isFormDirty.palladium) handleSubmit(e, 'palladium');
        if (isFormDirty.platinum) handleSubmit(e, 'platinum');
    };

    // Handle modal close
    const handleClose = () => {
        closeModal(); // Close the modal
    };

    // Render the form modal
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Precious Metals Details</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>
                <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}> {/* Tab container with dynamic active tab */}
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="gold">Gold</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="silver">Silver</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="palladium">Palladium</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="platinum">Platinum</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="gold">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.gold} onSubmit={(e) => handleSubmit(e, 'gold')}>
                                <Form.Group controlId="investedAmount">
                                    <Form.Control
                                        type="text"
                                        placeholder="Total Invested Amount *"
                                        required
                                        value={goldFormData.investedAmount}
                                        onChange={(e) => handleInputChange(e, 'gold')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the invested amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="number"
                                        placeholder="Weight in gm *"
                                        required
                                        value={goldFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'gold')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="investedDate">
                                    <Form.Control
                                        type="date"
                                        value={goldFormData.investedDate}
                                        onChange={(e) => handleInputChange(e, 'gold')}
                                        placeholder="Invested Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => e.target.showPicker()} // This makes the input field open the date picker when clicked
                                    />
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="silver">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.silver} onSubmit={(e) => handleSubmit(e, 'silver')}>
                                <Form.Group controlId="investedAmount">
                                    <Form.Control
                                        type="text"
                                        placeholder="Invested Amount *"
                                        required
                                        value={silverFormData.investedAmount}
                                        onChange={(e) => handleInputChange(e, 'silver')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the invested amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="text"
                                        placeholder="Weight in gm *"
                                        required
                                        value={silverFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'silver')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="investedDate">
                                    <Form.Control
                                        type="date"
                                        value={silverFormData.investedDate}
                                        onChange={(e) => handleInputChange(e, 'silver')}
                                        placeholder="Invested Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => e.target.showPicker()} // This makes the input field open the date picker when clicked
                                    />
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="palladium">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.palladium} onSubmit={(e) => handleSubmit(e, 'palladium')}>
                                <Form.Group controlId="investedAmount">
                                    <Form.Control
                                        type="text"
                                        placeholder="Invested Amount *"
                                        required
                                        value={palladiumFormData.investedAmount}
                                        onChange={(e) => handleInputChange(e, 'palladium')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the invested amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="text"
                                        placeholder="Weight in gm *"
                                        required
                                        value={palladiumFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'palladium')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="investedDate">
                                    <Form.Control
                                        type="date"
                                        value={palladiumFormData.investedDate}
                                        onChange={(e) => handleInputChange(e, 'palladium')}
                                        placeholder="Invested Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => e.target.showPicker()} // This makes the input field open the date picker when clicked
                                    />
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="platinum">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.platinum} onSubmit={(e) => handleSubmit(e, 'platinum')}>
                                <Form.Group controlId="investedAmount">
                                    <Form.Control
                                        type="text"
                                        placeholder="Invested Amount *"
                                        required
                                        value={platinumFormData.investedAmount}
                                        onChange={(e) => handleInputChange(e, 'platinum')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the invested amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="text"
                                        placeholder="Weight in gm *"
                                        required
                                        value={platinumFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'platinum')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="investedDate">
                                    <Form.Control
                                        type="date"
                                        value={platinumFormData.investedDate}
                                        onChange={(e) => handleInputChange(e, 'platinum')}
                                        placeholder="Invested Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => e.target.showPicker()} // This makes the input field open the date picker when clicked
                                    />
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <div className="btn-container">
                    <Button variant="primary" onClick={handleSubmitAll}>
                        Submit
                    </Button>
                </div>
                {/* Form with validation */}
            </Modal.Body>
        </Modal>
    );
};

// Export the PreciousMetalsForm component as the default export
export default PreciousMetalsForm;
