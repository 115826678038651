import React, { useState, useMemo, useEffect } from 'react';
import { message } from "antd";
import { fetchData } from '../../utils/helpers';

import HoldingSummary from '../HoldingSummary';
import PieChart from '../PieChart';
import ApexChart from '../ApexChart';
import DynamicTable from '../DynamicTable';
import Analytics from '../Analytics';
import TrendingStocks from '../TrendingStocks';
import Loader from '../Loader'; // Import the loader component

function MutualFundAnalytics() {
  const [selectedChart, setSelectedChart] = useState('current');
  const [analyticsValues, setAnalyticsValues] = useState({}); // State to hold asset values
  const [holdingInsights, setHoldingInsights] = useState({}); // State to hold asset values
  const [distribution, setDistribution] = useState([]);
  const [baseDailyTrend, setBaseDailyTrend] = useState({}); // State to hold asset values
  const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); // State to hold asset values
  const [referenceDailyTrend, setReferenceDailyTrend] = useState({}); // State to hold asset values
  const [referenceWeeklyTrend, setReferenceWeeklyTrend] = useState({}); // State to hold asset values

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);

  const token = null; // Replace with the actual token or use a secure method
  const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/mf/analytics';
  const method = 'GET';
  const localStorageKey = 'mutualFundAnalyticsData';
  // Fetch asset data when the component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchData(token, url, method, localStorageKey);
        updateStateWithData(data);
      } catch (error) {
        message.error({
          content: `Error fetching analytics data: ${error.message}`,
          duration: 3, // Duration in seconds
      });
        setError(`Error fetching analytics data: ${error.message}`)
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  loadData();
  }, [token, url, method, localStorageKey]);
  
  const updateStateWithData = (result) => {
    // Assuming the API returns an object with asset values
    setAnalyticsValues(result);
    setHoldingInsights(result.holdings_insights)
    setDistribution(result.holdings_insights.distribution);

    const baseDailyTrend = result.historic_trend.base_trend.daily_data;
    setBaseDailyTrend(baseDailyTrend);
    const baseWeeklyTrend = result.historic_trend.base_trend.weekly_data;
    setBaseWeeklyTrend(baseWeeklyTrend);
    const referenceDailyTrend = result.historic_trend.reference_trend.daily_data;
    setReferenceDailyTrend(referenceDailyTrend);
    const referenceWeeklyTrend = result.historic_trend.reference_trend.weekly_data;
    setReferenceWeeklyTrend(referenceWeeklyTrend);
  }
  const isDataLoaded = useMemo(() => analyticsValues && Object.keys(analyticsValues).length > 0, [analyticsValues]);

  const pieDataProfitLoss = [
    ['Profit', 70, 70],
    ['Loss', 30, 30],
  ];

  const gainersData = [
    { id: 1, name: 'Tata Motors Ltd', price: '₹1121.65', change: '4.4%', logo: '../images/tatamotors.png' },
    { id: 2, name: 'G R Power & Urban Infra', price: '₹154.00', change: '2.8%', logo: '../images/broker-icon.png' },
    { id: 3, name: 'One97', price: '₹765.40', change: '3.1%', logo: '../images/gold.png' },
  ];
  
  const losersData = [
    { id: 1, name: 'Company X', price: '₹980.50', change: '-1.5%', logo: '../images/tatamotors.png' },
    { id: 2, name: 'Company Y', price: '₹542.80', change: '-2.3%', logo: '../images/broker-icon.png' },
    { id: 3, name: 'Company Z', price: '₹120.20', change: '-3.8%', logo: '../images/gold.png' },
  ];

  const initialRows = [
    {
      id: 'row1',
      columns: [
        { id: '1', header: 'Portfolio Summary', contentId: 'holding-summary'}
      ],
    },
    {
      id: 'row2',
      columns: [
        { id: '2', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
      ],
    },
    {
      id: 'row3',
      columns: [
        { id: '3', header: 'Geo Allocation', contentId: 'geo-distribution'},
        { id: '4', header: 'Market Cap', contentId: 'market-cap'},
      ],
    },
    {
      id: 'row4',
      columns: [
        { id: '5', header: 'Sector Allocation', contentId: 'sector-distribution'},
        { id: '6', header: 'Asset Classes', contentId: 'holdings'},
      ]
    },
    {
      id: 'row5',
      columns: [
        { id: '7', header: 'Portfolio Breakdown by Mutual Fund', contentId: 'mutual-fund-distribution'},
        { id: '8', header: 'Top 20 Companies by Mutual Fund Investment', contentId: 'company'},
      ]
    },
    {
      id: 'row6',
      columns: [
        { id: '9', header: 'Unrealized Gain Breakdown by Mutual Fund Holdings', contentId: 'unrealized-gain' },
        { id: '10', header: '', contentId: ''}
      ]
    },
    {
      id: 'row7',
      columns: [
        { id: '11', header: 'Portfolio Performance by Mutual Fund Holdings', contentId: 'portfolio-performance'}
      ]
    },
    {
      id: 'row8',
      columns: [
        { id: '12', header: 'Top Gainers', contentId: 'trending-stocks-gainers'},
        { id: '13', header: 'Top Losers', contentId: 'trending-stocks-losers'}
      ]
    }
  ];

  const contentMap = useMemo(() => ({
    'holding-summary' : <HoldingSummary data={analyticsValues.holdings_summary} pieChartData={pieDataProfitLoss}/>,
    'historic-trend' : <div className='holding-chart-container'>
                          <ApexChart baseTrend={baseDailyTrend} referenceTrend={referenceDailyTrend} baseWeeklyTrend={baseWeeklyTrend} referenceWeeklyTrend={referenceWeeklyTrend}/>
                        </div>,
    'geo-distribution' : <PieChart pieData={analyticsValues.geo_distribution} id='chart2' width='480' height='250' />,
    'market-cap' : <PieChart pieData={analyticsValues.market_cap} id='chart3' width='480' height='250' />,
    'sector-distribution' : <PieChart pieData={analyticsValues.sector_distribution} id='chart4' width='480' height='250' />,
    'holdings' : <PieChart pieData={analyticsValues.holdings} id='chart5' width='480' height='250' />,
    'mutual-fund-distribution' : <div className="piechart-wrapper checkbox-heading">
                                    {/* <h2 className='heading'>Mutual Fund Distribution</h2> */}
                                    <div className='checkbox-wrapper'>
                                      <label className='analytics-label'>
                                        <input type='radio' className='analytics-checkbox' name='chartSelector' value='current' checked={selectedChart === 'current'} onChange={() => setSelectedChart('current')}/>
                                        Current
                                      </label>
                                      <label className='analytics-label'>
                                        <input type='radio'className='analytics-checkbox'  name='chartSelector' value='invested' checked={selectedChart === 'invested'} onChange={() => setSelectedChart('invested')} />
                                        Invested
                                      </label>
                                    </div>
                                  
                                    {selectedChart === 'current' && (
                                      <PieChart pieData={distribution.curr_val} id='current' width='480' height='250' />
                                    )}
                                    {selectedChart === 'invested' && (
                                      <PieChart pieData={distribution.investment} id='invested' width='480' height='250' />
                                    )}
                                  </div>,
    'company' : <div className="piechart-wrapper checkbox-heading">
                  <div className='checkbox-wrapper adjust-height'></div>
                  <PieChart pieData={analyticsValues.companies} id='company' width='480' height='250' />
                </div>,
    'unrealized-gain' : <PieChart pieData={holdingInsights.unrealised_gain} id='unrealized-gain' width='480' height='250' />,
    'portfolio-performance' : <div className="piechart-wrapper">
                                <DynamicTable tableData={holdingInsights.change}/>
                              </div>,
    'trending-stocks-gainers' : <div className='piechart-wrapper'>
                          <TrendingStocks stocksData={gainersData}/>
                        </div>,
    'trending-stocks-losers' : <div className='piechart-wrapper'>
                          <TrendingStocks stocksData={losersData}/>
                        </div>
  }), [isDataLoaded, analyticsValues, holdingInsights, distribution, baseDailyTrend, baseWeeklyTrend, referenceDailyTrend, referenceWeeklyTrend, selectedChart]);
  
  return (
    <div>
    {isLoading ? (
                <Loader /> // Show the loader while data is loading
            ) : error ? (
              <div>{error}</div>
            ) : (
    <Analytics initialRows={initialRows} contentMap={contentMap} module={'mf-analytics'}/>
     )}
     </div>
  );
}

export default MutualFundAnalytics;
