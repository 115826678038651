import React, {useState, useEffect} from "react";
import { message } from "antd";
import { fetchData, removeLocalStorageItem } from "../../utils/helpers";

import TotalPortfolioValue from "../TotalPortfolioValue";
import '../../css/MutualFund.css';

import DynamicTabs from "../DynamicTabs";
import MutualFundsLIst from './MutualFundsLIst';
import MutualFundAnalytics from './MutualFundAnalytics';
import MutualFundTransactions from './MutualFundTransactions';
import Loader from "../Loader";

function MutualFund() {
    
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [mutualFundList, setMutualFundList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    const token = null; // Replace with the actual token or use a secure method
    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/mf';
    const method = 'GET';
    const localStorageKey = 'mutualFundList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [token, url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(token, url, method, localStorageKey, refresh);
            setPortfolioValue(data);
            setMutualFundList(data.asset_details)
            setPortfolioValue1DChange(data['1d_change'])
            setPortfolioValueReturns(data['returns'])
        } catch (error) {
            message.error({
                content: `Error fetching mutual fund list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        removeLocalStorageItem('mutualFundList');
        removeLocalStorageItem('mutualFundAnalyticsData');
        removeLocalStorageItem('mutualFundTransactionList');
    };

    const portfolioData = {
        module: 'MutualFund',
        value: portfolioValue.curr_val,
        dayChangeValue: portfolioValue1DChange[0],
        percentage: portfolioValue1DChange[1],
        invested: portfolioValue.investment,
        returns: portfolioValueReturns[0],
        returnspercent: portfolioValueReturns[1],
        plusIcon: false,
    };
    
    const tabs = [
        { key: 'mutualfundslist', name: 'My Mutual Funds', component: MutualFundsLIst },
        { key: 'analytics', name: 'Analytics', component: MutualFundAnalytics },
        { key: 'transactions', name: 'Transactions', component: MutualFundTransactions },
      ];
    return (
        <div>
        {isLoading ? (
            <Loader /> // Show the loader while data is loading
        ) : (
        <div className="container mutualfund pad-right-unset pad-left-unset">
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh}/>
            <DynamicTabs tabs={tabs} data={mutualFundList} module={'mutualfunds'}/>
        </div>
        )}
        </div>
    );
}

export default MutualFund;