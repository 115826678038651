import styled from "styled-components";

export const Header = styled.header`
	.input-nav {
		margin-left:5rem !important;
		width:25rem;
	}

	.ml-auto {
		display: flex;
		align-items: center;
		gap: 18rem;
	}
	
	.menu-container {
		display: flex;
    	gap: 4rem;
	}
	
	.menu-container a {
		text-decoration: unset;
    	color: #000000;
	}

	@media(max-width:920px) {
		.input-nav {
			display:none;
		}
	}
`;