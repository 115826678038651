// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form } from 'react-bootstrap'; // Import Modal, Button, and Form components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees } from '../../utils/helpers';

// Initial form data state
const initialFormData = {
    propertyName: '', // Property name
    propertyAddress: '', // Property address
    propertyPurchasePrice: '', // Purchase price of the property
    propertyCurrentPrice: '', // Current price of the property
    propertyPurchaseDate: '', // Purchase date of the property
    propertyNotes: '', // Additional notes
};

// Define the RealEstateForm functional component
const RealEstateForm = ({ isOpen, closeModal, onFormSubmit, filledData }) => {
    
    // State for form validation and form data
    const [validated, setValidated] = useState(false); // Validation state
    const [formData, setFormData] = useState(initialFormData); // Form data state
    const [isFormDirty, setIsFormDirty] = useState(false); // Flag to check if form has been modified
  
    // Effect to reset form data when modal opens
    useEffect(() => {
        if (isOpen) {
            setFormData(filledData || initialFormData); // Reset form data if modal is open
            setValidated(false); // Clear validation state
            setIsFormDirty(false); // Clear dirty flag
        }
    }, [isOpen, filledData]);
  
    // Handle input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target; // Get input field ID and value
        let newValue = value;

        // If input is for a number field, remove commas for processing
        if (id === 'propertyPurchasePrice' || id === 'propertyCurrentPrice') {
            newValue = value.replace(/,/g, ''); // Remove commas
            newValue = formatRupees(newValue); // Add commas
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true); // Mark form as dirty
    };
  
    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const form = event.currentTarget; // Get the form element
    
        // Check form validity
        if (form.checkValidity() === false) {
            event.stopPropagation(); // Stop submission if form is invalid
            setValidated(true); // Show validation feedback
        } else {
            try {
                // Replace this URL with your actual API endpoint
                const response = await fetch('https://your-api-endpoint.com/submit', {
                    method: 'POST', // Use POST method for form submission
                    headers: {
                        'Content-Type': 'application/json', // Set content type to JSON
                    },
                    body: JSON.stringify(formData), // Convert formData to JSON
                });
        
                if (response.ok) {
                    // Handle successful response
                    closeModal(); // Close the modal on success
                } else {
                    // Handle API errors
                    console.error('API Error:', response.statusText);
                }
            } catch (error) {
                // Handle network or other errors
                console.error('API Error:', error);
                // Call the parent's callback function with form data
                onFormSubmit({ ...formData, id: filledData ? filledData.id : Date.now() }); // Pass the updated object to the callback
                closeModal(); // Close the modal
            }
        }
    };

    // Check if all fields are empty
    const areFieldsEmpty = Object.values(formData).every(value => value === '');
  
    // Handle modal close
    const handleClose = () => {
        // Only ask for confirmation if the form is dirty and not all fields are empty
        if (isFormDirty && !areFieldsEmpty) {
            const confirmClose = window.confirm('Are you sure you want to exit? Unsaved changes will be lost.');
            if (!confirmClose) {
                return; // Prevent closing if user cancels
            }
        }
        closeModal(); // Close the modal
    };

    // Render the form modal
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Property Details</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>
                {/* Form with validation */}
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="propertyName">
                        <Form.Control
                            type="text"
                            placeholder="Name *"
                            required
                            value={formData.propertyName}
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="propertyAddress">
                        <Form.Control
                            type="text"
                            placeholder="Address"
                            value={formData.propertyAddress}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="propertyPurchasePrice">
                        <Form.Control
                            type="text"
                            placeholder="Purchase Price *"
                            required
                            value={formData.propertyPurchasePrice}
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the purchase price.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="propertyCurrentPrice">
                        <Form.Control
                            type="text"
                            placeholder="Current Price"
                            value={formData.propertyCurrentPrice}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="propertyPurchaseDate">
                        <Form.Control
                            type="date"
                            value={formData.propertyPurchaseDate}
                            onChange={handleInputChange}
                            placeholder="Purchase Date" // Note: The placeholder might not show in some browsers with type="date"
                            style={{
                                width: '100%',
                                cursor: 'pointer',
                            }}
                            onFocus={(e) => e.target.showPicker()} // This makes the input field open the date picker when clicked
                        />
                    </Form.Group>
                    <Form.Group controlId="propertyNotes">
                        <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Notes"
                            value={formData.propertyNotes}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="propertySubmit" className="btn-container">
                        <Button variant="primary" type="submit">
                            { filledData ? 'Update' : 'Save' } {/* Button text depending on context */}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

// Export the RealEstateForm component as the default export
export default RealEstateForm;
