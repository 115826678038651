import React from "react";
import '../../css/StockCell.css';

function RealEstateCell ({data, onCellClick}) {
    return (
        <div className="broker-cell-container" data-key={data.id} onClick={() => onCellClick(data)}>
            <div className="broker-cell-row">
                <div className="broker-cell-icon">
                    <img alt="" loading="lazy" width="32" height="32" decoding="async" data-nimg="1" src='../../images/realestate.png' style={{color: 'transparent'}}/>
                </div>
                <div className="broker-cell-name-container">
                    <p className="broker-cell-name" style={{textAlign: 'unset'}}>{data.propertyName}</p>
                </div>
                <div>
                    <div className="broker-cell-price-container">
                        <i class='fas fa-edit' style={{color: '#bdbdbd'}}></i>
                    </div>
                </div>
            </div>
            <div className="broker-cell-row padding-unset">
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Investment</p>
                    <p className="broker-cell-account">₹{data.propertyPurchasePrice}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Current Value</p>
                    <p className="broker-cell-account">₹{data.propertyCurrentPrice}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Returns</p>
                    <p className="broker-cell-account">0%</p>
                </div>
            </div>
        </div>
    );
}

export default RealEstateCell;