import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import CryptoAnalytics from './CryptoCurrencyAnalytics';
import CryptoTransactions from './CryptoCurrencyTransactions';
import Loader from "../Loader";

import { fetchData, removeLocalStorageItem } from "../../utils/helpers";
import { message } from "antd";
import CryptoCurrencyForm from "./CryptoCurrencyForm";

function CryptoCurrency() {
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);
    // State to store the data of crypto
    const [cryptoCurrency, setCryptoCurrency] = useState([]);

    const token = null; // Replace with the actual token or use a secure method
    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/cryptocurrency';
    const method = 'GET';
    const localStorageKey = 'cryptoCurrencyList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [token, url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(token, url, method, localStorageKey, refresh);
            setPortfolioValue(data);
            setCryptoCurrencyList(data.asset_details)
            setPortfolioValue1DChange(data['1d_change'])
            setPortfolioValueReturns(data['returns'])
        } catch (error) {
            message.error({
                content: `Error fetching ind stock list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        removeLocalStorageItem('cryptoCurrencyList');
        removeLocalStorageItem('cryptoCurrencyAnalyticsData');
        removeLocalStorageItem('cryptoCurrencyTransactionList')
    };

    const portfolioData = {
        module: 'cryptocurrency',
        value: portfolioValue.curr_val,
        dayChangeValue: portfolioValue1DChange[0],
        percentage: portfolioValue1DChange[1],
        invested: portfolioValue.investment,
        returns: portfolioValueReturns[0],
        returnspercent: portfolioValueReturns[1],
        plusIcon: true,
    };
    
    const tabs = [
        { key: 'list', name: 'Crypto Assets', component: StockList },
        { key: 'analytics', name: 'Analytics', component: CryptoAnalytics },
        { key: 'transactions', name: 'Transactions', component: CryptoTransactions },
    ];
    
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = (formData) => {
        setCryptoCurrency([...cryptoCurrency, formData]);
        console.log(formData)
        closeModal(); // Close the modal after submission
    };
    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : (
            <div className="container cryptocurrency pad-right-unset pad-left-unset">
                <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} openForm={() => openModal(null)}/>
                <DynamicTabs tabs={tabs} data={cryptoCurrencyList} module={'cryptocurrency'}/>
                <CryptoCurrencyForm 
                    isOpen={isModalOpen} 
                    closeModal={closeModal} 
                    onFormSubmit={handleFormSubmit} 
                />
                <a href="#" class="float">
                    <i class="fa fa-plus my-float"></i>
                </a>
            </div>
        )}
        </div>
    );
}

export default CryptoCurrency;