import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import IndiaStocksAnalytics from './IndiaStocksAnalytics';
import IndiaStocksTransactions from './IndiaStocksTransactions';
import Loader from "../Loader";

import { fetchData, removeLocalStorageItem } from "../../utils/helpers";
import { message } from "antd";

function IndiaStocks() {
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [indiaStockList, setIndiaStockList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    const token = null; // Replace with the actual token or use a secure method
    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/indiastocks';
    const method = 'GET';
    const localStorageKey = 'indiaStockList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [token, url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(token, url, method, localStorageKey, refresh);
            setPortfolioValue(data);
            setIndiaStockList(data.asset_details)
            setPortfolioValue1DChange(data['1d_change'])
            setPortfolioValueReturns(data['returns'])
        } catch (error) {
            message.error({
                content: `Error fetching ind stock list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        removeLocalStorageItem('indiaStockList');
        removeLocalStorageItem('indiaStocksAnalyticsData');
        removeLocalStorageItem('indiaStocksTransactionList')
    };

    const portfolioData = {
        module: 'indiastocks',
        value: portfolioValue.curr_val,
        dayChangeValue: portfolioValue1DChange[0],
        percentage: portfolioValue1DChange[1],
        invested: portfolioValue.investment,
        returns: portfolioValueReturns[0],
        returnspercent: portfolioValueReturns[1],
        plusIcon: false,
    };
    
    const tabs = [
        { key: 'stocklist', name: 'My Stocks', component: StockList },
        { key: 'analytics', name: 'Analytics', component: IndiaStocksAnalytics },
        { key: 'transactions', name: 'Transactions', component: IndiaStocksTransactions },
      ];
    
    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : (
            <div className="container indiaStock pad-right-unset pad-left-unset">
                <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh}/>
                <DynamicTabs tabs={tabs} data={indiaStockList} module={'indiastocks'}/>
            </div>
        )}
        </div>
    );
}

export default IndiaStocks;