import React, {useState} from 'react';
import Sidebar from './navbar/Sidebar';
import Navbar from './navbar/Navbar';
import '../css/Header.css';
import '../css/Sidebar.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <div className="container-fluid pad-un">
            <Navbar toggleSidebar={toggleSidebar}/>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md">
                <div className="container-fluid p-2">
                    <div className="form-inline ml-auto mg-right">
                        <span onClick={toggleSidebar} className='toggle-bar'>
                            <i className="fa fa-bars fa-large"></i>
                        </span>
                    </div>
                    <a className="navbar-brand text-primary mr-0">MoneyLens</a>
                    
                </div>
            </nav> */}
            <div className={`sidebar ${isOpen ? 'active' : ''}`}>
                <Sidebar />
            </div>
            <div className={`sidebar-overlay ${isOpen ? 'active' : ''}`} onClick={toggleSidebar}></div>
        </div>
    );
};

export default Header;