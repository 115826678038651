import React, { useEffect, useState, useMemo } from "react";
import { formatRupees } from "../utils/helpers";
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

function ApexChart({ baseTrend, referenceTrend, baseWeeklyTrend, referenceWeeklyTrend }) {
    const [selectedTab, setSelectedTab] = useState("5D");

    const [graphLineColor, setGraphLineColor] = useState('#34a853');
    const [graphMiddleColor, setGraphMiddleColor] = useState('#b8e1c3');

    const [profitLossClass, setProfitLossClass] = useState('');
    const [rotateClass, setRotateClass] = useState('');

    const [absoluteValue, setAbsoluteValue] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState('');

    const [niftyPercentage, setNiftyPercentage] = useState(0);
    const [niftyProfitLossClass, setNiftyProfitLossClass] = useState('');
    const [niftyRotateClass, setNiftyRotateClass] = useState('');

    // Determine which trend data to use based on the selected tab
    const isLongTerm = useMemo(() => ["3Y", "5Y", "MAX"].includes(selectedTab), [selectedTab]);
    const currentGraphData = isLongTerm ? baseWeeklyTrend : baseTrend;
    const currentReferenceData = isLongTerm ? referenceWeeklyTrend : referenceTrend;

    // Memoized downsampled data
    const downsampledGraphData = useMemo(() => currentGraphData, [currentGraphData]);
    const downsampledReferenceData = useMemo(() => currentReferenceData, [currentReferenceData]);

    // Memoize the series data
    const series = useMemo(() => [
        {
            name: 'Nifty 50',
            data: downsampledReferenceData,
        },
        {
          name: 'This Fund',
          data: downsampledGraphData,
        }
    ], [downsampledGraphData, downsampledReferenceData]);

    // Memoize the options
    const options = useMemo(() => ({
        chart: {
            id: 'area-datetime',
            type: 'area',
            height: 350,
            zoom: {
                autoScaleYaxis: true,
                enabled: false
            },
        },
        colors: ['#808080', graphMiddleColor],
        annotations: {
            yaxis: [{ y: 30, borderColor: '#999' }],
            xaxis: [{ x: new Date('14 Nov 2012').getTime(), borderColor: '#999', yAxisIndex: 0 }],
        },
        dataLabels: { enabled: false },
        markers: { size: 0, style: 'hollow' },
        yaxis: {
            title: { text: 'Price' },
            labels: {
                formatter: (value) => Math.round(value),
            },
        },
        xaxis: {
            type: 'datetime',
            min: new Date('01 Mar 2012').getTime(),
            tickAmount: 6,
        },
        stroke: {
            curve: 'smooth',
            width: [2, 2],
            colors: ['#808080', graphLineColor],
        },
        fill: {
            type: ['solid', 'gradient'],
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
            },
            opacity: 0
        },
    }), [graphLineColor, graphMiddleColor]);

    useEffect(() => {
        if (!downsampledGraphData.length) return;

        let latestDataPoint = downsampledGraphData[downsampledGraphData.length - 1];
        let latestDate = latestDataPoint[0];

        const latestNiftyDataPoint = downsampledReferenceData[downsampledReferenceData.length - 1];
        let latestNiftyDate = latestNiftyDataPoint[0];

        const calculateZoom = (startDate) => {
            ApexCharts.exec('area-datetime', 'zoomX', startDate, latestDate);
        };

        switch (selectedTab) {
            case "5D":
                const latestFiveDay = downsampledGraphData[downsampledGraphData.length - 5];
                calculateZoom(latestFiveDay[0]);
                calculateAbsoluteValueAndPercentage(latestFiveDay[1], latestDataPoint[1]);

                const latestNiftyFiveDay = downsampledReferenceData[downsampledReferenceData.length - 5];
                calculateNiftyPercentage(latestNiftyFiveDay[1], latestNiftyDataPoint[1]);
                break;
            case '1M':
                const latestOneMonth = downsampledGraphData[downsampledGraphData.length - 30];
                calculateZoom(latestOneMonth[0]);
                calculateAbsoluteValueAndPercentage(latestOneMonth[1], latestDataPoint[1]);

                const latestNiftyOneMonth = downsampledReferenceData[downsampledReferenceData.length - 30];
                calculateNiftyPercentage(latestNiftyOneMonth[1], latestNiftyDataPoint[1]);
                break;
            case "3M":
                const threeMonthsAgoDate = new Date(latestDate);
                threeMonthsAgoDate.setMonth(threeMonthsAgoDate.getMonth() - 3);
                const threeMonthsAgoIndex = findClosestDataPoint(downsampledGraphData, threeMonthsAgoDate.getTime());
                if (threeMonthsAgoIndex !== -1) {
                    calculateZoom(threeMonthsAgoIndex[0]);
                }
                calculateAbsoluteValueAndPercentage(threeMonthsAgoIndex[1], latestDataPoint[1])

                const threeMonthsAgoNiftyDate = new Date(latestNiftyDate);
                threeMonthsAgoNiftyDate.setMonth(threeMonthsAgoNiftyDate.getMonth() - 3);
                const threeMonthsAgoNiftyIndex = findClosestDataPoint(downsampledReferenceData, threeMonthsAgoNiftyDate.getTime());
                calculateNiftyPercentage(threeMonthsAgoNiftyIndex[1], latestNiftyDataPoint[1])
                break;
            case '6M':
                const sixMonthsAgoDate = new Date(latestDate);
                sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 6);
                const sixMonthsAgoIndex = findClosestDataPoint(downsampledGraphData, sixMonthsAgoDate.getTime());
                if (sixMonthsAgoIndex !== -1) {
                    calculateZoom(sixMonthsAgoIndex[0]);
                }
                calculateAbsoluteValueAndPercentage(sixMonthsAgoIndex[1], latestDataPoint[1]);

                const sixMonthsAgoNiftyDate = new Date(latestNiftyDate);
                sixMonthsAgoNiftyDate.setMonth(sixMonthsAgoNiftyDate.getMonth() - 3);
                const sixMonthsAgoNiftyIndex = findClosestDataPoint(downsampledReferenceData, sixMonthsAgoNiftyDate.getTime());
                calculateNiftyPercentage(sixMonthsAgoNiftyIndex[1], latestNiftyDataPoint[1])
                break;
            case '1Y':
                const oneYearAgoDate = new Date(latestDate);
                oneYearAgoDate.setFullYear(oneYearAgoDate.getFullYear() - 1);
                const oneYearAgoIndex = findClosestDataPoint(downsampledGraphData, oneYearAgoDate.getTime());
                if (oneYearAgoIndex !== -1) {
                    calculateZoom(oneYearAgoIndex[0]);
                }
                calculateAbsoluteValueAndPercentage(oneYearAgoIndex[1], latestDataPoint[1]);

                const oneYearAgoNiftyDate = new Date(latestNiftyDate);
                oneYearAgoNiftyDate.setMonth(oneYearAgoNiftyDate.getMonth() - 3);
                const oneYearAgoNiftyIndex = findClosestDataPoint(downsampledReferenceData, oneYearAgoNiftyDate.getTime());
                calculateNiftyPercentage(oneYearAgoNiftyIndex[1], latestNiftyDataPoint[1])
                break;
            case "3Y":
                const threeYearsAgoDate = new Date(latestDate);
                threeYearsAgoDate.setFullYear(threeYearsAgoDate.getFullYear() - 3);
                const threeYearsAgoIndex = findClosestDataPoint(downsampledGraphData, threeYearsAgoDate.getTime());
                if (threeYearsAgoIndex !== -1) {
                    calculateZoom(threeYearsAgoIndex[0]);
                }
                calculateAbsoluteValueAndPercentage(threeYearsAgoIndex[1], latestDataPoint[1]);

                const threeYearAgoNiftyDate = new Date(latestNiftyDate);
                threeYearAgoNiftyDate.setMonth(threeYearAgoNiftyDate.getMonth() - 3);
                const threeYearAgoNiftyIndex = findClosestDataPoint(downsampledReferenceData, threeYearAgoNiftyDate.getTime());
                calculateNiftyPercentage(threeYearAgoNiftyIndex[1], latestNiftyDataPoint[1])
                break;
            case "5Y":
                const fiveYearsAgoDate = new Date(latestDate);
                fiveYearsAgoDate.setFullYear(fiveYearsAgoDate.getFullYear() - 5);
                const fiveYearsAgoIndex = findClosestDataPoint(downsampledGraphData, fiveYearsAgoDate.getTime());
                if (fiveYearsAgoIndex !== -1) {
                    calculateZoom(fiveYearsAgoIndex[0]);
                }
                calculateAbsoluteValueAndPercentage(fiveYearsAgoIndex[1], latestDataPoint[1])

                const fiveYearAgoNiftyDate = new Date(latestNiftyDate);
                fiveYearAgoNiftyDate.setMonth(fiveYearAgoNiftyDate.getMonth() - 3);
                const fiveYearAgoNiftyIndex = findClosestDataPoint(downsampledReferenceData, fiveYearAgoNiftyDate.getTime());
                calculateNiftyPercentage(fiveYearAgoNiftyIndex[1], latestNiftyDataPoint[1])
                break;
            case "MAX":
                const minTimestamp = Math.min(...downsampledGraphData.map(item => item[0]));
                const minValue = Math.min(...downsampledGraphData.map(item => item[1]));
                calculateZoom(minTimestamp);
                calculateAbsoluteValueAndPercentage(minValue, latestDataPoint[1])

                const minNiftyValue = Math.min(...downsampledReferenceData.map(item => item[1]));
                calculateNiftyPercentage(minNiftyValue, latestNiftyDataPoint[1])
                break;
            default:
                break;
        }
    }, [downsampledGraphData, selectedTab]);

    // Common function to find the closest data point
    const findClosestDataPoint = (data, referenceDate) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i][0] >= referenceDate) {
              return data[i];
            }
        }
    };

    let totalAbsPercetage = '';
    const calculateAbsoluteValueAndPercentage = (entryAbsValue, exitAbsValue) => {
        // Determine if exitValue is greater or smaller than entryValue
        if (exitAbsValue > entryAbsValue) {
            // Calculate percentage increase
            totalAbsPercetage = ((exitAbsValue - entryAbsValue) / entryAbsValue) * 100;
            setRotateClass('');
            setProfitLossClass('profit-color');

            setGraphLineColor('#34a853');
            setGraphMiddleColor('#b8e1c3');
        } else if (exitAbsValue < entryAbsValue) {
            // Calculate percentage decrease
            totalAbsPercetage = ((entryAbsValue - exitAbsValue) / entryAbsValue) * 100;
            setRotateClass('rotate-text');
            setProfitLossClass('loss-color');

            setGraphLineColor('#ea4335');
            setGraphMiddleColor('#f7bab5');
        }
        setTotalPercentage(`${(totalAbsPercetage).toFixed(2)}%`);
        setAbsoluteValue((exitAbsValue - entryAbsValue).toFixed(2));
    }

    const calculateNiftyPercentage = (entryAbsNiftyValue, exitAbsNiftyValue) => {
    
        if (exitAbsNiftyValue > entryAbsNiftyValue) {
          setNiftyPercentage(`${(((exitAbsNiftyValue - entryAbsNiftyValue) / entryAbsNiftyValue) * 100).toFixed(2)}%`);
          setNiftyProfitLossClass('profit-color');
          setNiftyRotateClass('');
        } else if (exitAbsNiftyValue < entryAbsNiftyValue) {
          setNiftyPercentage(`${(((entryAbsNiftyValue - exitAbsNiftyValue) / entryAbsNiftyValue) * 100).toFixed(2)}%`);
          setNiftyProfitLossClass('loss-color');
          setNiftyRotateClass('rotate-text');
        }
      }
    const tabs = ['5D', '1M', '3M', '6M', '1Y', '3Y', '5Y', 'MAX'];

    return (
        <div id="line-chart">
            <div className="company-info-graph-profit">
                {/* <span className="company-info-graph-profit-number text-bold">₹{formatRupees(absoluteValue)} <span className={classNames('', profitLossClass)}><span className={classNames('', rotateClass)}>▲</span>{totalPercentage}</span>
                </span> */}
                <span style={{paddingLeft: '2rem', marginRight: '2rem', color: '#999999'}}>
                Nifty 50:<span style={{fontWeight: '700'}} className={classNames('', niftyProfitLossClass)}><span className={classNames('', niftyRotateClass)}>▲</span>{niftyPercentage}</span>
                </span>
                <span className="this-fund-col">
                This Fund: <span className="company-info-graph-profit-number text-bold">₹{formatRupees(absoluteValue)} <span className={classNames('', profitLossClass)}><span className={classNames('', rotateClass)}>▲</span>{totalPercentage}</span>
                </span>
                </span>
                
            </div>
            <div className="company-info-tab-container">
                {tabs.map((tab) => (
                    <span
                        key={tab}
                        className={classNames('company-info-tab', { active: selectedTab === tab })}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </span>
                ))}
            </div>
            <Chart options={options} series={series} type="area" height={350} />
        </div>
    );
}

export default ApexChart;
