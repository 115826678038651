import React from 'react';
import '../css/Loader.css'; // Create this CSS file to style the loader

const Loader = () => {
  return (
    <div className="loader-container">
      <i className="fas fa-spinner loader-icon"></i> {/* Use Font Awesome spinner icon */}
    </div>
  );
};

export default Loader;
