import React, { useState } from "react";
import { formatDate } from "../../utils/helpers";
import RsusEsopForm from "./RsusEsopForm";

function RsusTransactionList({ transaction }) {
    const [transactionDetails, setTransactionDetails] = useState(transaction);
    const [showModal, setShowModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    
    const handleEdit = (transaction) => {
        setCurrentTransaction(transaction);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentTransaction(null);
    };

    const handleFormSubmit = (updatedTransaction) => {
        setTransactionDetails((prevDetails) =>
            prevDetails.map((details) =>
                details.id === updatedTransaction.id ? updatedTransaction : details
            )
        );
        handleCloseModal();
    };

    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Investment</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Current Value</th>
                            <th scope="col">Returns</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionDetails.length > 0 ? (
                            transactionDetails.map(details => (
                                <tr key={details.id}>
                                    <td>{details.name}</td>
                                    <td>₹{details.investedAmount}</td>
                                    <td>{details.quantity}</td>
                                    <td>₹{details.curr_val}</td>
                                    <td>{details.returns[1]}%</td>
                                    <td>{formatDate(parseInt(details.investedDate))}</td>
                                    <td>
                                        <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); handleEdit(details); }}>Edit</a>&nbsp;&nbsp;&nbsp;
                                        <a href="javascript:void(0)">Delete</a>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No transactions available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Render the modal form */}
            {showModal && (
                <RsusEsopForm
                    isOpen={showModal}
                    closeModal={handleCloseModal}
                    onFormSubmit={handleFormSubmit}
                    initialFormData={currentTransaction}
                    defaultForm={currentTransaction?.type === 'listed' ? 'listed' : 'private'} // Set the form type based on transaction.type
                    onEdit={true}
                />
            )}
        </div>
    );
}

export default RsusTransactionList;
