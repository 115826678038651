import React from 'react';
import '../css/TotalPortfolioValue.css';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { isNegative, formatRupees } from '../utils/helpers';

function TotalPortfolioValue ({data, onRefresh, openForm }) {
    
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    const returnsPercentage = isNegative(parseFloat(data.percentage));
    const rotateClass = (returnsPercentage) ? 'rotate-text' : '';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 bg-header-container">
            <div className="bg-inner">
                <div className="icon-section">
                    <div className="icon-inner" onClick={() => handleNavigation('/assets')}>
                        <img alt="leftArrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src="../images/back-grey-icon.png"/>
                    </div>
                    <div className="icon-inner2">
                        <img alt="Info" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src="../images/info.png"/>
                    </div>
                </div>
                <div className="main-section">
                    <p className="main-section-p">Total Portfolio Value</p>
                    <div className="price-section">
                        <h3 className="price">₹{formatRupees(data.value)}</h3>
                        <img alt="Refresh" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src='../images/refresh.png' onClick={onRefresh}/>
                        {data.plusIcon && (
                            <img alt="Add" loading="lazy" width="36" height="36" decoding="async" data-nimg="1" className="margin-left-plus-icon" src='../images/plus.png' title={data.plusIconTitle} onClick={openForm}/>
                        )}
                    </div>
                    <div className="main-section-sub-p">
                        <span className="main-section-sub-span">1 Day Change: </span>
                        <span className={classNames("main-section-sub-span", profitLossClass)}>₹{formatRupees(data.dayChangeValue)}</span>
                        <span className={classNames("main-section-sub-span", profitLossClass)}><span className={classNames('', rotateClass)}>▲</span>{data.percentage}%</span>
                    </div>
                    <div className="main-section-sub-p total-invested">
                        <div className="main-section-sub-span marging-right-title title-color title-invested">
                            <span>Invested</span> 
                            <span className='subtitle-color'>₹{formatRupees(data.invested)}</span>
                        </div>
                        <div className="main-section-sub-span marging-right-title title-color title-invested">
                            <span>Returns</span> 
                            <span className='subtitle-color'>₹{formatRupees(data.returns)}</span>
                        </div>
                        <div className="main-section-sub-span marging-right-title title-color title-invested">
                            <span>Returns %</span> 
                            <span className='subtitle-color'>{data.returnspercent}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalPortfolioValue;