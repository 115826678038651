import React, {useState} from "react";
import { isNegative, isEmptyField } from '../utils/helpers';
import classNames from 'classnames';
import '../css/DynamicTable.css';

function DynamicTable ({ tableData }) {

    // Check if tableData is valid and data is an array
    const isValidData = !isEmptyField(tableData);

    const tableSingleData = (value) => {
        const isNegativeValue = isNegative(parseFloat(value[1])); // Determine if the value is negative
        const profitLossClass = isNegativeValue ? 'loss-color' : 'profit-color'; // Choose the CSS class based on the value
        const rotateClass = (isNegativeValue) ? 'rotate-text' : '';
        return (
          <td className='table-value-center'>
            <div>{value[0]}</div>
            <div className={profitLossClass}><span className={classNames('', rotateClass)}>▲</span>{value[1]}%</div>
          </td>
        );
    };

    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const [activeSortKey, setActiveSortKey] = useState(null);
    // Function to handle sorting logic
    const sortedData = isValidData ?  [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    }) : [];
    
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
        }
        setSortConfig({ key, direction });
        setActiveSortKey(key); // Set the active sort key
    };

    return (

      // table-striped
        <table className="table">
            <thead className='table-headerr'>
                <tr>
                    <th scope="col" className={`table-heading-data ${activeSortKey === 'name' ? 'active' : ''}`} onClick={() => handleSort('name')}>
                        <span className="table-heading-name">Mutual Fund Name</span> <i className="fas fa-caret-down" aria-hidden="true"></i>
                    </th>
                    <th scope="col" className={`table-heading-data table-value-center ${activeSortKey === '1d_change' ? 'active' : ''}`} onClick={() => handleSort('1d_change')}>
                    <span  className="table-heading-name">1D Change</span> <i className="fas fa-caret-down" aria-hidden="true"></i>
                    </th>
                    <th scope="col" className={`table-heading-data table-value-center ${activeSortKey === '1w_change' ? 'active' : ''}`} onClick={() => handleSort('1w_change')}>
                    <span className="table-heading-name">1W Change</span> <i className="fas fa-caret-down" aria-hidden="true"></i>
                    </th>
                    <th scope="col" className={`table-heading-data table-value-center ${activeSortKey === '1m_change' ? 'active' : ''}`} onClick={() => handleSort('1m_change')}>
                    <span className="table-heading-name">1M Change</span> <i className="fas fa-caret-down" aria-hidden="true"></i>
                    </th>
                </tr>
            </thead>
          <tbody>
          {isValidData && sortedData.length > 0 ? (
              sortedData.map(item => (
                <tr key={item.id} className='table-heading-data table-single-row'>
                  <td scope="row" className='table-heading-data'>{item.name}</td>
                  {tableSingleData(item['1d_change'])}
                  {tableSingleData(item['1w_change'])}
                  {tableSingleData(item['1m_change'])}
                </tr>
              ))
            ) : (
              <tr>
                  <td colSpan="4" className="text-center">No data available</td>
              </tr>
          )}
          </tbody>
        </table>
    );
}

export default DynamicTable;