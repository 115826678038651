import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { message } from "antd";
import { formatRupees, formatDateToInputValue, fetchData } from "../../utils/helpers";

function PrivateListedForm({ isOpen, closeModal, onFormSubmit, initialFormData, formType }) {
    const [allCompanies, setAllCompanies] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [formData, setFormData] = useState({ ...initialFormData });
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [validated, setValidated] = useState(false);
    const [currency, setCurrency] = useState('INR');
    const [exchangeRate, setExchangeRate] = useState(1); // Default to 1 (INR to INR)

    const token = null; // Replace with actual token
    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/rsus/company';
    const method = 'GET';
    const localStorageKey = 'rsusEsopCompanyList';

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(token, url, method, localStorageKey, refresh);
            setAllCompanies(formType === 'listed' ? data.listed : data.private);
        } catch (error) {
            message.error(`Error fetching rsus list: ${error.message}`);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setFormData({
                ...initialFormData,
                investedDate: initialFormData.investedDate ? formatDateToInputValue(initialFormData.investedDate) : "",
            });
            setValidated(false);
            setIsFormDirty(false);
        }
    }, [isOpen, initialFormData]);

    const handleSearchInputChange = (e) => {
        const query = e.target.value.toLowerCase();
        setFormData((prevData) => ({ ...prevData, name: query }));

        if (query.length > 2) {
            const filteredResults = allCompanies.filter((company) => company.companyName.toLowerCase().includes(query));
            setSearchResults(filteredResults.length ? filteredResults : [{ companyName: "No data found" }]);
        } else {
            setSearchResults([]);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        if (id === "investedAmount") {
            newValue = value.replace(/,/g, "");
            newValue = formatRupees(newValue);
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true);
    };

    const handleSelectResult = (result) => {
        setSelectedValue(result);
        setFormData((prevData) => ({
            ...prevData,
            name: result,
        }));
        setSearchResults([]);
        setIsFormDirty(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            // Additional validation
            if (!formData.name || !formData.quantity || !formData.investedAmount) {
                setValidated(true);
                return;
            }

            try {
                await onFormSubmit({ ...formData, id: Date.now(), type: formType, currency });
                closeModal(); // Close the modal on successful submit
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const fetchExchangeRate = async () => {
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/INR"); // Use any exchange rate API
            const data = await response.json();
            setExchangeRate(data.rates.USD); // Update with current INR to USD rate
        } catch (error) {
            message.error(`Error fetching exchange rate: ${error.message}`);
        }
    };

    const handleCurrencyToggle = async () => {
        setCurrency(currency === 'INR' ? 'USD' : 'INR');

        if (currency === 'INR') {
            // Switch to USD
            await fetchExchangeRate();
            const amountInINR = parseFloat(formData.investedAmount.replace(/,/g, '')) || 0;
            const amountInUSD = (amountInINR / exchangeRate).toFixed(2); // Convert to USD
            setFormData((prevData) => ({
                ...prevData,
                investedAmount: amountInUSD,
            }));
            setCurrency('USD');
        } else {
            // Switch back to INR
            const amountInUSD = parseFloat(formData.investedAmount.replace(/,/g, '')) || 0;
            const amountInINR = (amountInUSD * exchangeRate).toFixed(2); // Convert back to INR
            setFormData((prevData) => ({
                ...prevData,
                investedAmount: formatRupees(amountInINR),
            }));
            setCurrency('INR');
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name">
                <Form.Control
                    type="text"
                    value={formData.name}
                    onChange={handleSearchInputChange}
                    placeholder={formType === 'listed' ? "Search company name *" : "Enter company name *"}
                    required
                    autoComplete="off"
                />
                <div style={{ position: "relative" }}>
                    <ul className="list-group search-results" style={{ position: "absolute", top: "100%", left: "0", zIndex: "1000", width: "100%" }}>
                        {searchResults.map((result, index) => (
                            <li
                                key={index}
                                className="list-group-item"
                                onClick={() => handleSelectResult(result.companyName)}
                                style={{ cursor: "pointer" }}
                            >
                                {result.companyName}
                            </li>
                        ))}
                    </ul>
                </div>
                <Form.Control.Feedback type="invalid">
                    {formType === 'listed' ? 'Please select the company name.' : 'Please enter the company name.'}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="quantity">
                <Form.Control
                    type="number"
                    placeholder="No. of stocks vested *"
                    value={formData.quantity}
                    autoComplete="off"
                    onChange={handleInputChange}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please enter the no. of stocks.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="investedDate">
                <Form.Control
                    type="date"
                    value={formData.investedDate}
                    onChange={handleInputChange}
                    autoComplete="off"
                    placeholder="Invested Date"
                    style={{ width: '100%', cursor: 'pointer' }}
                    onFocus={(e) => e.target.showPicker()}
                />
            </Form.Group>

            <Form.Group controlId="investedAmount">
                <div className="input-container">
                    <span className="input-label">{currency === 'USD' ? '$' : '₹'}</span>
                    <Form.Control
                        type="text"
                        placeholder="Stock price at vested time"
                        value={formData.investedAmount}
                        autoComplete="off"
                        onChange={handleInputChange}
                    />
                </div>
                <Form.Control.Feedback type="invalid">
                    Please enter the stock price.
                </Form.Control.Feedback>
            </Form.Group>

            <div className="form-check form-switch">
                <label className="form-check-label" htmlFor="currencySwitch">Add price in USD</label>
                <input className="form-check-input" type="checkbox" role="switch" id="currencySwitch" onChange={handleCurrencyToggle} />
            </div>

            <div className="btn-container">
                <Button variant="primary" type="submit">
                    Save
                </Button>
            </div>
        </Form>
    );
}

export default PrivateListedForm;
