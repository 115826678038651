import React, { useState, useEffect } from 'react';
import TransactionList from '../TransactionList';
import { fetchData } from '../../utils/helpers';
import { message } from 'antd';
import Loader from "../Loader";

function MutualFundTransactions() {

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [transactionList, setTransactionList] = useState([]);

  const token = null; // Replace with the actual token or use a secure method
  const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/mf/transactions';
  const method = 'GET';
  const localStorageKey = 'mutualFundTransactionList';

  // Fetch asset data when the component mounts
  useEffect(() => {
    loadData();
  }, [token, url, method, localStorageKey]);

  const loadData = async (refresh = false) => {
      try {
          const data = await fetchData(token, url, method, localStorageKey, refresh);
          console.log(data)
          setTransactionList(data.transactions_data)
      } catch (error) {
          message.error({
              content: `Error fetching transaction list: ${error.message}`,
              duration: 3, // Duration in seconds
          });
      } finally {
          setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  return (
    <div>
      {isLoading ? 
        ( <Loader /> ) 
        : (
          <div className="broker-main-container">
            <TransactionList data={transactionList}/>
          </div>
        )}
    </div>
  );
}

export default MutualFundTransactions;
