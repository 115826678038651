import React, {useState} from "react";
import TotalPortfolioValue from "../TotalPortfolioValue"; // Import the TotalPortfolioValue component
import PreciousMetalsForm from "./PreciousMetalsForm";
import PreciousMetalsCell from "./PreciousMetalsCell";

function PreciousMetals () {

    // Define the initial portfolio data
    const portfolioData = {
        module: 'RealEstate', // Module name
        value: '2,124', // Value of the portfolio
        dayChangeValue: 0, // Change in value over the day
        percentage: 0.02, // Percentage change
        invested: '1,780', // Amount invested
        returns: '396', // Returns from investment
        returnspercent: '0.02', // Returns percentage
        plusIcon: true, // Flag to show the plus icon
        plusIconTitle: 'Add Precious Metals' // Title for the plus icon
    };

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);
    // State to store the list of precious metals
    const [preciousMetals, setPreciousMetals] = useState([]);

    // Function to open the modal
    const openModal = () => setIsModalOpen(true);

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = (formData) => {
        const { id, investedAmount } = formData;
        setPreciousMetals((prevMetals) => {
            const existingMetal = prevMetals.find(metal => metal.id === id);
            if (existingMetal) {
                // Update the existing metal's value
                return prevMetals.map(metal =>
                    metal.id === id
                        ? { ...metal, investedAmount: (parseFloat(metal.investedAmount) + parseFloat(investedAmount)).toString() }
                        : metal
                );
            } else {
                // Add the new metal to the array
                return [...prevMetals, formData];
            }
        });
        closeModal(); // Close the modal after submission
    };

    const handleRefresh = () => {
    };
    return (
        <div className="container precious-metals pad-right-unset pad-left-unset">
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} openForm={() => openModal()}/>
            {/* Render the PreciousMetalsForm component with props for modal state, closing, and form submission */}
            <PreciousMetalsForm 
                isOpen={isModalOpen} 
                closeModal={closeModal} 
                onFormSubmit={handleFormSubmit}
            />
            {/* Container for the list of precious metals cells */}
            <div className="tab-content">
                <div className="container broker-main-container real-estate">
                    {
                        preciousMetals.map((data) => (
                            <PreciousMetalsCell 
                                key={data.id} // Unique key for each cell based on ID
                                data={data} // Pass data to the cell
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default PreciousMetals;