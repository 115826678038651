import './css/App.css';
import React from 'react';
// import Navbar from './navbarcomponents/Navbar';
// import Sidebar from './navbarcomponents/Sidebar';
import Header from './components/Header';
// import './css/Sidebar.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import NetWorth from './components/NetWorth';
import Assets from './components/Assets';
import Debts from './components/Debts';

import MutualFund from './components/mutualfunds/MutualFund';
import IndiaStocks from './components/indiastocks/IndiaStocks';
import USStocks from './components/usstocks/USStocks';
import CryptoCurrency from './components/cryptocurrency/CryptoCurrency';
import RsusEsop from './components/rsus-esop/RsusEsop';
import RealEstate from './components/realestate/RealEstate';
import PreciousMetals from './components/preciousmetals/PreciousMetals';
import PreciousMetalsDetails from './components/preciousmetals/PreciousMetalsDetails';

import CompanyInformation from './components/CompanyInformation';

function App() {
  return (
    <Router>
      <div className="app-layout">
        {/* <Sidebar /> */}
        <div className="main-content">
          {/* <Navbar /> */}
          <Header />
          <Routes>
            <Route path="/" element={<Navigate to="/networth" replace />} />
            <Route path="/networth" element={<NetWorth />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="/debts" element={<Debts />} />
            <Route path="/assets/mutualfund" element={<MutualFund />} />
            <Route path="/assets/indiastocks" element={<IndiaStocks />} />
            <Route path="/assets/usstocks" element={<USStocks />} />
            <Route path="/assets/cryptocurrency" element={<CryptoCurrency />} />
            <Route path="/assets/rsusesop" element={<RsusEsop />} />
            <Route path="/assets/realestate" element={<RealEstate />} />
            <Route path="/assets/preciousmetals" element={<PreciousMetals />} />
            <Route path="/assets/preciousmetals/details/:id" element={<PreciousMetalsDetails />} />
            <Route path="/assets/indiastocks/information/:id" element={<CompanyInformation />} />
            <Route path="/assets/usstocks/information/:id" element={<CompanyInformation />} />
            <Route path="/assets/mutualfund/information/:id" element={<CompanyInformation />} />
            <Route path="/assets/cryptocurrency/information/:id" element={<CompanyInformation />} />
            <Route path="/assets/rsusesop/information/:id" element={<CompanyInformation />} />
            {/* Add more routes as needed */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;