import React from "react";
import '../../css/CompanyInformation.css';
import DynamicProgressBar from "../DynamicProgressBar";

function SectorHolding () {
    const value = '50'
    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row">
                <div className="sector-holding-heading-container">
                    <h4 className="heading">Sector Holding</h4>
                    <div className="scheme-info-details-subheading">as on 31 Jul 2024</div>
                </div>
                <div className="scheme-info-details-value-main-column">
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-rest-row-value">Cash Holding</span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container" style={{width: '300px'}}>
                            <DynamicProgressBar value={value} />
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-rest-row-value">₹146.09 Cr</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectorHolding;