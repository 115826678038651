// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal } from 'react-bootstrap'; // Import Modal, Button, Form components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import PrivateListedForm from './PrivateListedForm';

// Define the RsusEsopForm functional component
const RsusEsopForm = ({ isOpen, closeModal, onFormSubmit, initialFormData, defaultForm, onEdit }) => {
  // State for form validation and form data for both forms

    const [activeForm, setActiveForm] = useState(defaultForm); // State to toggle between forms
    const [listedFormData, setListedFormData] = useState(initialFormData);
    const [privateFormData, setPrivateFormData] = useState(initialFormData);

    useEffect(() => {
        if(onEdit){
            if (activeForm === 'listed') {
            // When switching to 'listed', reset 'private' form data to blank
            setPrivateFormData(resetFormFields(initialFormData));
            } else if (activeForm === 'private') {
            // When switching to 'private', reset 'listed' form data to blank
            setListedFormData(resetFormFields(initialFormData));
            }
        }
    }, [activeForm]);

    // Utility function to reset form fields dynamically
    const resetFormFields = (data) => {
        return Object.keys(data).reduce((acc, key) => {
        acc[key] = ''; // Set each field to an empty string
        return acc;
        }, {});
    };

  // Render the form modal
  return (
    <Modal show={isOpen} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>RSUs / ESOPs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* Toggle Buttons */}
            <div className="toggle-button-group">
            <button
                className={`toggle-button ${activeForm === 'listed' ? 'active' : ''} ${onEdit && activeForm !== 'listed' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('listed')}
                disabled={onEdit}
            >
                Listed
            </button>
            <button
                className={`toggle-button ${activeForm === 'private' ? 'active' : ''} ${onEdit && activeForm !== 'private' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('private')}
                disabled={onEdit}
            >
                Private
            </button>
            </div>

            <PrivateListedForm
                isOpen={isOpen}
                closeModal={closeModal}
                onFormSubmit={onFormSubmit}
                initialFormData={activeForm === 'listed' ? listedFormData : privateFormData}
                formType={activeForm}
            />
        </Modal.Body>
    </Modal>
  );
};

export default RsusEsopForm;
