// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form } from 'react-bootstrap'; // Import Modal, Button, Form components from React Bootstrap
import Select from 'react-select'; // Import react-select for searchable dropdown
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { message } from "antd";
import { formatRupees, formatDateToInputValue, fetchData } from "../../utils/helpers";

// Define the RsusEsopForm functional component
const USStocksForm = ({ isOpen, closeModal, onFormSubmit, initialFormData, defaultForm, onEdit }) => {
  // State for form validation and form data for both forms

    const [activeForm, setActiveForm] = useState(defaultForm); // State to toggle between forms
    const [formData, setFormData] = useState({ ...initialFormData });
    const [allCompanies, setAllCompanies] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [validated, setValidated] = useState(false);
    const [currency, setCurrency] = useState('INR');
    const [exchangeRate, setExchangeRate] = useState(1); // Default to 1 (INR to INR)
    const [selectedFile, setSelectedFile] = useState(null); // State for file upload
    const [addedCompanies, setAddedCompanies] = useState([]); // Array to store added companies
    const [selectedOption, setSelectedOption] = useState(null);

    const token = null; // Replace with actual token
    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets/rsus/company';
    const method = 'GET';
    const localStorageKey = 'rsusEsopCompanyList';

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (isOpen) {
            setFormData({
                name: '',
                quantity: '',
                investedAmount: '',
                investedDate: '',
            });
            setSelectedOption(null); // Reset react-select
            setValidated(false);
            setIsFormDirty(false);
            setAddedCompanies([]);
        }
    }, [isOpen]);

    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(token, url, method, localStorageKey, refresh);
            setAllCompanies(data.listed);
        } catch (error) {
            message.error(`Error fetching rsus list: ${error.message}`);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setTimeout(() => {
            console.log(selectedFile)
        }, 100);
    };
    
    const companyOptions = allCompanies.map(company => ({
        value: company.id,
        label: company.companyName,
    }));

    useEffect(() => {
        if (isOpen) {
            setFormData({
                ...initialFormData,
                investedDate: initialFormData.investedDate ? formatDateToInputValue(initialFormData.investedDate) : "",
            });
            setValidated(false);
            setIsFormDirty(false);
        }
    }, [isOpen, initialFormData]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        if (id === "investedAmount") {
            newValue = value.replace(/,/g, "");
            newValue = formatRupees(newValue);
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
    
        // Check if form is valid
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            // Additional validation for required fields
            if (!selectedOption || !formData.name || !formData.quantity && addedCompanies.length === 0) {
                setValidated(true); // If the form is blank and nothing has been added, show validation error
                return;
            }
    
            try {
                // If there is data in the form, add it to the added companies list
                if (formData.name && formData.quantity && formData.investedAmount) {
                    setAddedCompanies([...addedCompanies, { ...formData, id: Date.now(), currency }]);
                }
    
                // Call the callback function with the entire list of added companies
                await onFormSubmit([...addedCompanies, { ...formData, id: Date.now(), currency }]);
    
                // Clear the form after successful submission
                setFormData({
                    name: '',
                    quantity: '',
                    investedAmount: '',
                    investedDate: '',
                });
                setValidated(false); // Reset validation state
                closeModal(); // Close the modal on successful submit
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };
    

    const fetchExchangeRate = async () => {
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/INR"); // Use any exchange rate API
            const data = await response.json();
            setExchangeRate(data.rates.USD); // Update with current INR to USD rate
        } catch (error) {
            message.error(`Error fetching exchange rate: ${error.message}`);
        }
    };

    const handleCurrencyToggle = async () => {
        setCurrency(currency === 'INR' ? 'USD' : 'INR');

        if (currency === 'INR') {
            // Switch to USD
            await fetchExchangeRate();
            const amountInINR = parseFloat(formData.investedAmount.replace(/,/g, '')) || 0;
            const amountInUSD = (amountInINR / exchangeRate).toFixed(2); // Convert to USD
            setFormData((prevData) => ({
                ...prevData,
                investedAmount: amountInUSD,
            }));
            setCurrency('USD');
        } else {
            // Switch back to INR
            const amountInUSD = parseFloat(formData.investedAmount.replace(/,/g, '')) || 0;
            const amountInINR = (amountInUSD * exchangeRate).toFixed(2); // Convert back to INR
            setFormData((prevData) => ({
                ...prevData,
                investedAmount: formatRupees(amountInINR),
            }));
            setCurrency('INR');
        }
    };

    const handleAddMore = (event) => {
        event.preventDefault();
        
        if (!selectedOption || !formData.name || !formData.quantity) {
            setValidated(true); // Validate the form fields
            return;
        }
        
        // Add the current form data to the addedCompanies list
        setAddedCompanies([...addedCompanies, { ...formData, id: Date.now(), currency }]);
        
        // Reset the form fields
        setFormData({
            name: '',
            quantity: '',
            investedAmount: '',
            investedDate: '',
        });
        // Reset the select input
        setSelectedOption(null); // Clear selected option
        
        setValidated(false); // Reset validation
        setIsFormDirty(false); // Mark form as clean
    };
    
    const handleDelete = (id) => {
        // Remove the item from the list
        setAddedCompanies(addedCompanies.filter((item) => item.id !== id));
    };
    
    const handleEdit = (id) => {
        // Find the item to edit
        const itemToEdit = addedCompanies.find((item) => item.id === id);
    
        // Find the corresponding company option
        const selectedCompany = companyOptions.find(option => option.value === itemToEdit.name);
    
        // Fill the form with the selected item's data for editing
        setFormData(itemToEdit);
        setSelectedOption(selectedCompany); // Set the selected option for react-select
    
        // Remove the item from the list for now
        setAddedCompanies(addedCompanies.filter((item) => item.id !== id));
    };
    
    

  // Render the form modal
  return (
    <Modal show={isOpen} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* Toggle Buttons */}
            <div className="toggle-button-group">
            <button
                className={`toggle-button ${activeForm === 'manual' ? 'active' : ''} ${onEdit && activeForm !== 'manual' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('manual')}
                disabled={onEdit}
            >
                Manual
            </button>
            <button
                className={`toggle-button ${activeForm === 'upload' ? 'active' : ''} ${onEdit && activeForm !== 'upload' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('upload')}
                disabled={onEdit}
            >
                Upload File
            </button>
            </div>

            {activeForm === 'manual' ? (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                    <Select
                        className='select-company-name'
                        options={companyOptions}
                        placeholder="Select company name *"
                        onChange={(selectedOption) => {
                            setSelectedOption(selectedOption); // Update selected option
                            setFormData({ ...formData, name: selectedOption ? selectedOption.label : '' });
                        }}
                        value={selectedOption}
                        isSearchable
                        required
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                color: 'rgb(214 219 225)' // Change to your desired color
                            })
                        }}
                    />
                    {validated && !selectedOption && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select the company name.
                        </div>
                    )}
                </Form.Group>

                <Form.Group controlId="quantity">
                    <Form.Control
                        type="number"
                        placeholder="No. of stocks *"
                        value={formData.quantity}
                        autoComplete="off"
                        onChange={handleInputChange}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter the no. of stocks.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="investedAmount">
                    <div className="input-container">
                        <span className="input-label">{currency === 'USD' ? '$' : '₹'}</span>
                        <Form.Control
                            type="text"
                            placeholder="Stock price"
                            value={formData.investedAmount}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                    </div>
                </Form.Group>

                <div className="form-check form-switch">
                    <label className="form-check-label" htmlFor="currencySwitch">Add price in USD</label>
                    <input className="form-check-input" type="checkbox" role="switch" id="currencySwitch" onChange={handleCurrencyToggle} />
                </div>
                <Form.Group controlId="investedDate">
                    <Form.Control
                        type="date"
                        value={formData.investedDate}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder="Invested Date"
                        style={{ width: '100%', cursor: 'pointer' }}
                        onFocus={(e) => e.target.showPicker()}
                    />
                </Form.Group>
                <a className='add-more' onClick={handleAddMore}>Add More</a>
                {addedCompanies.length > 0 && (
                    <div className="added-companies-list">
                        <table className="companies-table">
                            <thead>
                                <tr>
                                    <th className='table-head-name'>Name</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addedCompanies.map((company) => (
                                    <tr key={company.id}>
                                        <td className='table-row-name'>
                                        <div className='name-wrapper'>
                                            <span>{company.name}</span>
                                        </div>
                                        </td>
                                        <td>
                                            <span>{company.quantity}</span>
                                        </td>
                                        <td>
                                            <span>{currency === 'USD' ? '$' : '₹'}{company.investedAmount}</span>
                                        </td>
                                        <td>
                                            <span>
                                                <i className='fas fa-edit edit-company' onClick={() => handleEdit(company.id)}></i>
                                                {/* <a className='edit-company' onClick={() => handleEdit(company.id)}>Edit</a> */}
                                                {/* <a className='delete-company' onClick={() => handleDelete(company.id)}>Delete</a> */}
                                                <i className='fas fa-trash delete-company' onClick={() => handleDelete(company.id)}></i>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="btn-container">
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </div>
            </Form>
            ) : (
                <>
                    <Form.Group controlId="fileUpload">
                    <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <div className='download-sample'><a href=''>Download Sample File</a></div>
                    <div className="btn-container">
                    <Button variant="primary" type="submit">
                        Upload
                    </Button>
                </div>
            </>
            )}
        </Modal.Body>
    </Modal>
  );
};

export default USStocksForm;
