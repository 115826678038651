import React, { useState, useEffect } from "react";
import '../css/Assets.css';
import { useNavigate } from 'react-router-dom';
import { fetchData, shortFormatRupees } from '../utils/helpers';
//import Loader from './Loader'; // Import the loader component


function Assets() {

    const navigate = useNavigate();

    const [assetValues, setAssetValues] = useState({}); // State to hold asset values
    const token = null; // Replace with the actual token or use a secure method
    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/assets';
    const method = 'GET';
    const localStorageKey = 'assets';
    // Fetch asset data when the component mounts
    useEffect(() => {
        const loadData = async () => {
            try {
              const data = await fetchData(token, url, method, localStorageKey);
              setAssetValues(data);
            } catch (error) {
              console.error('Failed to fetch data:', error);
              // Optionally handle error state here
            }
        };

        loadData();
    }, [url, method, localStorageKey, token]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/mutualfund')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-chart-bar icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Mutual Funds</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.mutual_funds) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/indiastocks')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-rupee-sign icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">India Stocks</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.india_stocks) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/usstocks')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-dollar-sign icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">US Stocks</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.us_stocks) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/rsusesop')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-balance-scale-left icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">RSUs/ESOPs</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.rsus) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-chart-line icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Pre-IPO Equity</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.pre_ipo) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/cryptocurrency')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <img alt="profileImage" src="images/crypto.svg" className="icon" style={{ width: "3rem", height: "3rem" }} />
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Crypto Currency</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.crypto) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/realestate')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-building icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Real Estate</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.real_estate) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/preciousmetals')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-gem icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Precious Metals</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.precious_metals) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-car-alt icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Vehicle</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.vehicle) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-landmark icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Banks/FD</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.banks_fd) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-euro-sign icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">EPF</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.epf) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-piggy-bank icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">PPF</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.ppf) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-child icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">NPS</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.nps) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-wallet icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Cash</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.cash) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-shapes icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Others</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.others) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center"></div>
            </div>
        </div>
    );
    
}

export default Assets;