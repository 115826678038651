import React from "react";
import { formatRupees } from "../utils/helpers";
import PieChart from "./PieChart";
import { Col } from "antd";

function HoldingSummary ({ data, pieChartData }) {
    const profitSummary = data.profit_summary;
    const lossSummary = data.loss_summary;
    return (
        <div className='holding-summary-main'>
            <Col className="col-md-9">
            <div className='holding-summary-container'>
                <div className='holding-summary-row'>
                    <div className='holding-summary-pie-chart'>
                        <PieChart pieData={pieChartData} id='chart1' width='175' height='175' status={false} profitLossColor={true}/>
                    </div>
                    <div className='holding-summary-flex width-10'>
                        <div className=''>
                            {/* <div><span className='text-bold'>5</span> of 6 <span className='text-bold'>In Profit</span></div> */}
                            <div><span className='text-bold'>{profitSummary.info}</span></div>
                            <div>
                                <span className='text-bold'>₹{formatRupees(profitSummary.total_profit[0])} </span>
                                <span className='profit-color'>▲{profitSummary.total_profit[1]}%</span>
                            </div>
                        </div>
                        <div className='border'></div>
                        <div className=''>
                            <div><span className='text-bold'>{lossSummary.info}</span></div>
                            <div>
                                <span className='text-bold'>₹{formatRupees(lossSummary.total_loss[0])} </span>
                                <span className='loss-color'><span className='rotate-text'>▲</span>{lossSummary.total_loss[1]}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='holding-summary-flex profit-loss-container'>
                    <div className='holding-summary-flex width-30'>
                    <h2 className='heading'>Highest Profit</h2>
                    <div>{data.highest_profit.name || 'Loading...'}</div>
                    <div>
                        <span className='text-bold'>₹{formatRupees(data.highest_profit.returns[0])} </span>
                        <span className='profit-color'>▲{data.highest_profit.returns[1]}%</span>
                    </div>
                    </div>
                    <div className='vertical-border margin-right-2'></div>
                    <div className='holding-summary-flex width-30'>
                        <h2 className='heading'>Highest Loss</h2>
                        <div>{data.highest_loss.name}</div>
                        <div>
                            <span className='text-bold'>₹{formatRupees(data.highest_loss.returns[0])} </span>
                            <span className='loss-color'><span className='rotate-text'>▲</span>{data.highest_loss.returns[1]}%</span>
                        </div>
                    </div>
                </div>
            </div>
            </Col>
            <Col className="col-md-3"></Col>
        </div>
    );
}

export default HoldingSummary ;