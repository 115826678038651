import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DynamicProgressBar = ({ value }) => {
  let variant;
  if (value < 30) variant = 'danger';
  else if (value < 60) variant = 'warning';
  else if (value < 90) variant = 'info';
  else variant = 'success';

  return (
    <ProgressBar now={value} label={`${value}%`} variant={variant} />
  );
};

export default DynamicProgressBar;
