import { message } from 'antd';

export const isNegative = (num) => {
    return num < 0 || Object.is(num, -0);
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

/**
 * Formats a number value as a currency string with commas.
 * 
 * @param {number|string} value - The value to format. Can be a number or a string.
 * @returns {string} - The formatted value as a string, or an empty string if the value is invalid.
 */
export const formatRupees = (value) => {
  if (isEmptyField(value)) {
      return ''; // Return an empty string for invalid values
  }

  // Convert value to a number if it's a string and ensure it's a valid number
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the value is a valid number
  if (isNaN(numberValue)) {
      return ''; // Return an empty string if the value is not a valid number
  }

  // Format the number with commas
  return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Formats a number into a string representation with suffixes 
 * like 'K' for thousand, 'L' for lakh, and 'Cr' for crore.
 *
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string with the appropriate suffix.
 */
export const shortFormatRupees = (number) => {
  const formatNumber = (num) => {
    const formatted = num.toFixed(2);
    // Remove '.00' if present
    return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
  };

  // Check if the number is greater than or equal to 1 Crore (10 million)
  if (number >= 10000000) {
    // Convert to Crore and format to 2 decimal places
    return formatNumber(number / 10000000) + ' Cr';
  } 
  // Check if the number is greater than or equal to 1 Lakh (100 thousand)
  else if (number >= 100000) {
    // Convert to Lakh and format to 2 decimal places
    return formatNumber(number / 100000) + ' L';
  } 
  // Check if the number is greater than or equal to 1 Thousand (1000)
  else if (number >= 1000) {
    // Convert to Thousand and format to 2 decimal places
    return formatNumber(number / 1000) + 'k';
  } 
  // If the number is less than 1000, return it as a string
  else {
    return formatRupees(number);
  }
};


/**
 * Checks if a value is null, undefined, or blank.
 * 
 * @param {any} value - The value to check.
 * @returns {boolean} - Returns true if the value is null, undefined, or blank; otherwise, false.
 */
export const isEmptyField = (value) => {
  if (value === null || value === undefined) {
      return true; // Value is null or undefined
  }

  if (typeof value === 'string') {
      return value.trim() === ''; // Check if string is blank
  }

  if (Array.isArray(value)) {
      return value.length === 0; // Check if array is empty
  }

  if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length === 0; // Check if object is empty
  }

  return false; // For numbers and other types, the value is not considered blank
};

export const apiRequest = async (url, method = 'GET', data = null, token = null) => {
    try {
      // Set up headers
      const headers = {};
  
      // If the data is a JavaScript object, assume JSON
      if (data && !(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
        data = JSON.stringify(data); // Convert the data object to a JSON string
      }
  
      // If a token is provided, include it in the Authorization header
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      // Set up the request options
      const options = {
        method,
        headers,
        body: data, // Body will be null for GET requests
      };
  
      // Make the API call
      const response = await fetch(url, options);
  
      // Handle HTTP errors
      if (!response.ok) {
        const errorText = await response.text(); // Capture the error message from the server
        throw new Error(`Error ${response.status}: ${errorText}`);
      }
  
      // Parse the JSON response
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('API request failed:', error.message);
      throw error; // Re-throw the error for further handling
    }
};
  

// Function to call the API and cache the data
const callApiAndCacheData = async (token, url, method, key) => {
  try {
    const result = await apiRequest(url, method, null, token);
    // Store fetched data in cache with a dynamic key
    localStorage.setItem(key, JSON.stringify(result));
    return result;
  } catch (error) {
    message.error({
      content: `Error fetching data: ${error.message}`,
      duration: 3, // Duration in seconds
  });
    throw error; // Rethrow error for handling in the calling function
  }
};

// Function to fetch data, either from cache or by calling the API
export const fetchData = async (token, url, method, key, refresh = false) => {
  try {
    // Check if data is available in cache with a dynamic key
    const cachedData = localStorage.getItem(key);

    if (cachedData && !refresh) {
      return JSON.parse(cachedData);
    } else {
      // If no cached data, call the API and return the result
      return await callApiAndCacheData(token, url, method, key);
    }
  } catch (error) {
      message.error({
          content: `Error in api: ${url}`,
          duration: 3, // Duration in seconds
      });
    throw error; // Rethrow error for handling in the calling function
  }
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key)
}

export const formatDateToInputValue = (timestamp) => {
  const date = new Date(parseInt(timestamp));
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};