// Import necessary modules and components from React and other files
import React, { useState } from "react";
import TotalPortfolioValue from "../TotalPortfolioValue"; // Import the TotalPortfolioValue component
import RealEstateForm from "./RealEstateForm"; // Import the RealEstateForm component
import RealEstateCell from "./RealEstateCell"; // Import the RealEstateCell component
import '../../css/MutualFund.css'; // Import CSS styles for the MutualFund component

// Define the RealEstate functional component
function RealEstate() {
    // Define the initial portfolio data
    const portfolioData = {
        module: 'RealEstate', // Module name
        value: '2,124', // Value of the portfolio
        dayChangeValue: 0, // Change in value over the day
        percentage: 0.02, // Percentage change
        invested: '1,780', // Amount invested
        returns: '396', // Returns from investment
        returnspercent: '0.02', // Returns percentage
        plusIcon: true, // Flag to show the plus icon
        plusIconTitle: 'Add Property' // Title for the plus icon
    };
    
    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);
    // State to store the list of real estate properties
    const [realEstates, setRealEstates] = useState([]);
    // State to keep track of the currently selected data for editing
    const [selectedData, setSelectedData] = useState(null);

    // Function to open the modal, optionally with data for editing
    const openModal = (property = null) => {
        setSelectedData(property); // Set the selected data
        setIsModalOpen(true); // Open the modal
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = (formData) => {
        if (selectedData) {
            // Update existing data if a property is selected
            setRealEstates(realEstates.map((estate) => 
                estate.id === selectedData.id ? formData : estate
            ));
        } else {
            // Add new data if no property is selected
            setRealEstates([...realEstates, formData]);
        }
        closeModal(); // Close the modal after submission
    };
    const handleRefresh = () => {
    };
    // Render the component
    return (
        <div className="container real-estate pad-right-unset pad-left-unset">
            {/* Render the TotalPortfolioValue component with portfolioData and openForm callback */}
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} openForm={() => openModal(null)}/>
            {/* Render the RealEstateForm component with props for modal state, closing, and form submission */}
            <RealEstateForm 
                isOpen={isModalOpen} 
                closeModal={closeModal} 
                onFormSubmit={handleFormSubmit} 
                filledData={selectedData}
            />
            {/* Container for the list of real estate cells */}
            <div className="tab-content">
                <div className="container broker-main-container real-estate">
                    {
                        // Map over the realEstates array to render a RealEstateCell for each property
                        realEstates.map((data) => (
                            <RealEstateCell 
                                key={data.id} // Unique key for each cell
                                data={data} // Pass data to the cell
                                onCellClick={() => openModal(data)} // Handle cell click to open the modal with property data
                            />
                        ))
                    }
                </div>
            </div>
            {/* Conditionally render the RealEstateForm component if there is selected data */}
            {selectedData && (
                <RealEstateForm 
                    isOpen={isModalOpen} 
                    closeModal={closeModal} 
                    onFormSubmit={handleFormSubmit} 
                    filledData={selectedData} 
                />
            )}
        </div>
    );
}

// Export the RealEstate component as the default export
export default RealEstate;
