import React from "react";
import '../../css/CompanyInformation.css'

function SchemeInformation () {
    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row">
                <h4 className="heading">Scheme Information</h4>
                <div className="scheme-info-details-heading-container">
                    <div className='scheme-info-details-heading-img-container'>
                        <img alt="" loading="lazy" width="40" height="40" decoding="async" data-nimg="1" src='../../../images/broker-icon.png' style={{color: 'transparent'}}/>
                    </div>
                    <div className="scheme-info-details-heading-name-container">
                        <h6 className="heading">Bandhan Money Manager Fund Direct-Growth</h6>
                        <div className='scheme-info-details-subheading'>
                            <span className="">as of 31 Jul 2024, 05:30 AM</span>
                        </div>
                    </div>
                </div>
                <div className="scheme-info-details-value-main-column">
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">₹6,059.09</span>
                            <span className="scheme-info-details-subheading">Scheme Asset Size</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Fund Type</span>
                            <span className="scheme-rest-row-value">Open End</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Exit Load</span>
                            <span className="scheme-rest-row-value">No charges on withdrawl</span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">0.10%</span>
                            <span className="scheme-info-details-subheading">Expense Ratio </span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Plan</span>
                            <span className="scheme-rest-row-value">Growth</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">2.41% </span>
                            <span className="scheme-info-details-subheading">Cash Holding</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Benchmark </span>
                            <span className="scheme-rest-row-value">NIFTY Money Market Index A-I</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Scheme Document</span>
                            <span className="scheme-rest-row-value">View</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SchemeInformation;