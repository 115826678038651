import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
//   CDBSidebarFooter,
//   CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div>
        <CDBSidebar textColor="#000000" backgroundColor="#ffffff">
            {/* <CDBSidebarHeader>
                <a href="/networth" className="text-decoration-none" style={{ color: 'inherit' }}>
                    MoneyLens
                </a>
            </CDBSidebarHeader> */}

            <CDBSidebarContent className="sidebar-content">
                <CDBSidebarMenu>
                    <NavLink exact='true' to="/networth" activeclassname="activeClicked">
                        <CDBSidebarMenuItem icon='chart-pie'>
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Net Worth</span>
                                <span className='beside-menu'>₹14,441</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='sub-menu'>Dashboard</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink exact='true' to="/assets" activeclassname="activeClicked">
                        <CDBSidebarMenuItem icon='seedling'>
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Assets</span>
                                <span className='beside-menu'>₹14,441</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink exact='true' to="/debts" activeclassname="activeClicked">
                        <CDBSidebarMenuItem icon="handshake">
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Debts</span>
                                <span className='beside-menu'>₹0</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                </CDBSidebarMenu>
            </CDBSidebarContent>

            {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
                style={{
                padding: '20px 5px',
                fontSize: '12px'
                }}
            >
                Get Moneylens on mobile
                Go to app.moneylens.in on your phone and follow instructions to install it as an app. Know more
            </div>
            </CDBSidebarFooter> */}
        </CDBSidebar>
    </div>
  );
};

export default Sidebar;