import React from "react";
import '../css/CompanyInformation.css'

function StockFundamentals () {
    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row">
                <h4 className="heading">Stock Fundamentals</h4>
                <div className="scheme-info-details-heading-container">
                    <div className='scheme-info-details-heading-img-container'>
                        <img alt="" loading="lazy" width="40" height="40" decoding="async" data-nimg="1" src='../../../images/broker-icon.png' style={{color: 'transparent'}}/>
                    </div>
                    <div className="scheme-info-details-heading-name-container">
                        <h6 className="heading">Bandhan Money Manager Fund Direct-Growth</h6>
                        <div className='scheme-info-details-subheading'>
                            <span className="bse-value">BSE: 543257</span>
                            <span className="nse-value">NSE: IRFC</span>
                        </div>
                    </div>
                </div>
                <div className="scheme-info-details-value-main-column">
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">₹6,059.09</span>
                            <span className="scheme-info-details-subheading font-1">Market Cap</span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">0.10%</span>
                            <span className="scheme-info-details-subheading font-1">Stock P/E </span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">2.41% </span>
                            <span className="scheme-info-details-subheading font-1">High / Low</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockFundamentals;